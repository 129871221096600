<form [formGroup]="addDriverForm" class="step_form_wrapper" appInvalidControlScroll>
	<!-- Main page content-->
	<section class="user_information">
		<h2 class="headinginfo"> Driver Settings </h2>
		<div class="container mt-1 ">
			<div class="row ">
				<div class="col-md-4">
					<mat-form-field appearance="outline">
						<mat-label>First Name <span class="asterisk">*</span></mat-label>
						<input matInput placeholder="First Name" formControlName="FirstName" tabindex="1"
							(focus)="$event.target.select()">
					</mat-form-field>
					<div *ngIf="submittedForm && f.FirstName.errors || f.FirstName.touched && f.FirstName.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.FirstName.errors.required">This field is required</div>
					</div>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="outline">
						<mat-label>Middle Name</mat-label>
						<input matInput placeholder="Middle Name" formControlName="MiddleName" tabindex="1"
							(focus)="$event.target.select()">
					</mat-form-field>
					<div *ngIf="submittedForm && f.MiddleName.errors || f.MiddleName.touched && f.MiddleName.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.MiddleName.errors.required">This field is required</div>
					</div>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="outline">
						<mat-label>Last Name <span class="asterisk">*</span></mat-label>
						<input matInput placeholder="Last Name" formControlName="LastName" tabindex="1"
							(focus)="$event.target.select()">
					</mat-form-field>
					<div *ngIf="submittedForm && f.LastName.errors || f.LastName.touched && f.LastName.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.LastName.errors.required">This field is required</div>
					</div>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="outline">
						<mat-label>Gender <span class="asterisk">*</span></mat-label>
						<mat-select formControlName="Gender" tabindex="1">
							<mat-option value="male">Male</mat-option>
							<mat-option value="female">Female</mat-option>
							<mat-option value="LGBTQIA+">X (LGBTQ+)</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="submittedForm && f.Gender.errors || f.Gender.touched && f.Gender.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.Gender.errors.required">This field is required</div>
					</div>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="outline" class="mat-custom-phone topLabelClass">
						<label class="mat-form-field-label mat-empty mat-form-field-empty labelClass">Cell<span
								class="asterisk"> *</span></label>
						<input matInput placeholder="1234567890" formControlName="CellNumber" ng2TelInput
							[ng2TelInputOptions]="{'preferredCountries': ['us', 'ca','mx','gb']}"
							(countryChange)="onCountryChange($event,'CellNumber')" class="phoneNumberClass"
							(intlTelInputObject)="telInputObjectCell($event)" tabindex="1"
							(focus)="$event.target.select()" />
					</mat-form-field>
					<div *ngIf="submittedForm && f.CellNumber.errors || f.CellNumber.touched && f.CellNumber.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.CellNumber.errors.required">This field is required</div>
					</div>
					<div *ngIf="f.CellNumber.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.CellNumber.errors?.invalid_Pattern;else plusError">Do not add Dashes
							with numbers</div>
						<ng-template #plusError>
							<div *ngIf="f.CellNumber.errors?.invalidPattern;else allErrors">Do not enter +
								sign</div>
							<ng-template #allErrors>
								<div *ngIf="f.CellNumber.errors?.pattern;else numberLength">Enter Numbers Without Spaces
									or Dashes
								</div>
								<ng-template #numberLength>
									<div *ngIf="f.CellNumber.errors.minlength">Minimum length should be 4</div>
									<div *ngIf="f.CellNumber.errors.maxlength">Maximum length should be 15</div>
								</ng-template>
							</ng-template>
						</ng-template>
					</div>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="outline">
						<mat-label>Driver E-mail<span class="asterisk">*</span>
						</mat-label>
						<input matInput placeholder="Driver E-mail" formControlName="Email" tabindex="1"
							(focus)="$event.target.select()">
					</mat-form-field>
					<div *ngIf="submittedForm && f.Email.errors || f.Email.touched && f.Email.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.Email.errors.required">This field is required</div>
					</div>
					<div *ngIf="f.Email.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.Email.errors.pattern">Email format should be valid</div>
					</div>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="outline">
						<mat-label>Dress <span class="asterisk">*</span></mat-label>
						<mat-select formControlName="Dress" tabindex="1">
							<mat-option value="">Choose Dress</mat-option>
							<mat-option *ngFor="let dress of dresses" [value]="dress.id">{{dress.name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="submittedForm && f.Dress.errors || f.Dress.touched && f.Dress.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.Dress.errors.required">This field is required</div>
					</div>
				</div>
				<!-- <span class="optionalTextStyle">(Example:1998)</span> -->
				<!-- <div class="col-md-4">
					<mat-form-field appearance="outline">
						<mat-label>First Year Pro Driver <span class="asterisk">*</span></mat-label>
						<input matInput formControlName="StartDate"
							placeholder="First Year Pro Driver" tabindex="1">
					</mat-form-field>
					<div *ngIf="submittedForm && f.StartDate.errors || f.StartDate.touched && f.StartDate.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.StartDate.errors.required">This field is required</div>
					</div>
					<div *ngIf="f.StartDate.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.StartDate.errors?.invalid_Pattern;else plusError">Do not add Dashes
							with numbers</div>
						<ng-template #plusError>
							<div *ngIf="f.StartDate.errors?.invalidPattern;else allErrors">Do not enter +
								sign</div>
							<ng-template #allErrors>
								<div *ngIf="f.StartDate.errors?.pattern;else numberLength">Enter Numbers Only
								</div>
								<ng-template #numberLength>
									<div *ngIf="f.StartDate.errors.minlength">Minimum length should be 4</div>
									<div *ngIf="f.StartDate.errors.maxlength">Maximum length should be 4</div>
								</ng-template>
							</ng-template>
						</ng-template>
					</div>
				</div> -->
				<div class="col-md-4">
					<mat-form-field appearance="outline">
						<mat-label>Number of years of experience <span class="asterisk">*</span></mat-label>
						<mat-select formControlName="StartDate" tabindex="1">
							<mat-option value="">Choose Year</mat-option>
							<mat-option *ngFor="let proDriverYear of proDriverYears" [value]="proDriverYear.value">
								{{ proDriverYear.label }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="submittedForm && f.StartDate.errors || f.StartDate.touched && f.StartDate.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.StartDate.errors.required">This field is required</div>
					</div>
					<div *ngIf="f.StartDate.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.StartDate.errors?.invalid_Pattern;else plusError">Do not add Dashes
							with numbers</div>
						<ng-template #plusError>
							<div *ngIf="f.StartDate.errors?.invalidPattern;else allErrors">Do not enter +
								sign</div>
							<ng-template #allErrors>
								<div *ngIf="f.StartDate.errors?.pattern;else numberLength">Enter Numbers Only
								</div>
								<ng-template #numberLength>
									<div *ngIf="f.StartDate.errors.minlength">Minimum length should be 4</div>
									<div *ngIf="f.StartDate.errors.maxlength">Maximum length should be 4</div>
								</ng-template>
							</ng-template>
						</ng-template>
					</div>
				</div>
				<!-- <div class="col-md-4">
					<label class="selectYearBusinessLabel">First Year Pro Driver
						<span class="asterisk">*</span></label>
					<div class="selectStyle">
						<select class="selectInputStyle" (click)="selectDropdownYearBusiness($event)"
							formControlName="StartDate" tabindex="1">
							<option *ngFor="let proDriverYear of proDriverYears" [value]="proDriverYear.value">
								{{ proDriverYear.label }}
							</option>
						</select>
						<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 63.9"
							style="enable-background: new 0 0 122.88 63.9" xml:space="preserve">
							<style type="text/css">
								.sty0 {
									fill-rule: evenodd;
									clip-rule: evenodd;
								}
							</style>
							<g>
								<polygon class="sty0" points="61.44,63.9 122.88,0 0,0 61.44,63.9" />
							</g>
						</svg>
					</div>
					<div *ngIf="
							(submittedForm && f.StartDate.errors) ||
							(f.StartDate.touched && f.StartDate.errors)
						" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.StartDate.errors.required">
							This field is required
						</div>
					</div>
				</div> -->

			</div>
		</div>
		<br>
	</section>

	<!--Language section-->
	<section class="user_information">
		<h2 class="headinginfo">Choose Fluent Language <span>(s)</span> <span class="asterisk">*</span></h2>
		<div class="container mt-1 ">
			<div class="row">
				<div class="col-md-12">
					<div class="choose_ameniteslist" *ngIf="!driverAdded; else driverAlreadyAdded">
						<div class="custom-control custom-checkbox" *ngFor="let language of languages">
							<input type="checkbox" class="custom-control-input" id="language_{{language.name}}"
								[value]="language.id"
								(change)="onLanguageChange($event.target.value,$event.target.checked)"
								[checked]="language.id==1 ? true : false" tabindex="1" (focus)="$event.target.select()">
							<label class="custom-control-label"
								for="language_{{language.name}}">{{language.name}}</label>
						</div>
					</div>
					<ng-template #driverAlreadyAdded>
						<div class="choose_ameniteslist">
							<div class="custom-control custom-checkbox"
								*ngFor="let languagesObj of languagesFormControl; let i=index;">
								<input type="checkbox" class="custom-control-input" id="amenity_{{languages[i].name}}"
									[value]="languages[i].id"
									(change)="onLanguageChange($event.target.value,$event.target.checked)"
									[formControl]="languagesObj" tabindex="1" (focus)="$event.target.select()">
								<label class="custom-control-label"
									for="amenity_{{languages[i].name}}">{{languages[i].name}}</label>
							</div>
						</div>
					</ng-template>
				</div>
				<div class="col-md-12">
					<div *ngIf="submittedForm && f.FluentLanguages.errors || f.FluentLanguages.touched && f.FluentLanguages.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.FluentLanguages.errors.required">This field is required</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- Main page content-->
	<section class="user_information">
		<h2 class="headinginfo">Driver Profile and Documents <span>- (IDs are internal use only)</span></h2>
		<div class="container mt-4">
			<div class="vehtype_extintwrap">
				<div class="row">
					<div class="col-md-4" *ngIf="DriverImage; else noDriverImage">
						<div class="vehtype_extint_potrait">
							<div style="display: flex;flex-direction: column;align-items: center;">
								<p>Driver - Head and Shoulder <span class="optionalTextStyle">(portrait) </span> <span
										class="asterisk">*</span></p>
								<img src="{{DriverImage}}" (click)="showImageInModal(DriverImage)" tabindex="-1">
								<ul class="mt-3">
									<li>
										<a class="custom-arrow-clockwise-button"
											(click)="fetchImageBlob(DriverImage ,'DriverImage',DriverImageId)">
											<i class="bi bi-arrow-clockwise"></i>rotate</a>
									</li>
									<li>
										<button class="changeuploaded" tabindex="1">
											<input type="file"
												(change)="vehicleOfficialImagesChange($event,'DriverImage',DriverImageId)"
												tabindex="-1" accept="image/jpeg, image/png">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Change
										</button>
									</li>
									<li><a class="delete-btn" (click)="deleteImage(DriverImageId,'DriverImage')"
											tabindex="1">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path
													d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
											</svg>
											Delete</a></li>
								</ul>
							</div>
						</div>
					</div>
					<ng-template #noDriverImage>
						<div class="col-md-4">
							<div class="vehtype_extint_potrait">
								<div style="display: flex;flex-direction: column;align-items: center;">
									<p [class.ng-invalid]="submittedForm && f.DriverImage.errors.required">Driver - Head
										and
										Shoulder <span class="optionalTextStyle">(portrait) </span> <span
											class="asterisk">*</span></p>
									<div class="emptyImage">
										<img src="../../../../assets/images/empty_image_potrait.png" tabindex="-1">
									</div>
									<ul class="mt-3">
										<li>
											<button class="adduploaded" tabindex="1">
												<input type="file"
													(change)="vehicleOfficialImagesChange($event,'DriverImage',DriverImageId)"
													tabindex="-1" accept="image/jpeg, image/png">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0V0z" />
													<circle cx="12" cy="12" r="3" />
													<path
														d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
												</svg>
												Add
											</button>
										</li>
									</ul>
								</div>
								<div *ngIf="submittedForm && f.DriverImage.errors"
									class="text-danger margin-bottom-thin my-mat-hint text-center mb-4">
									<div *ngIf="f.DriverImage.errors.required">The Driver - Head and Shoulder (portrait)
										is
										required.</div>
								</div>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="DriverLicense; else noDriverLicense">
						<div class="vehtype_extint">
							<div>
								<p>Driver / Chauffeur License <span class="optionalTextStyle">(Front) </span> <span
										class="asterisk">*</span></p>
								<img src="{{DriverLicense}}" (click)="showImageInModal(DriverLicense)" tabindex="-1">
								<ul class="mt-3">
									<li>
										<a class="custom-arrow-clockwise-button"
											(click)="fetchImageBlob(DriverLicense ,'DriverLicense',DriverLicenseId)">
											<i class="bi bi-arrow-clockwise"></i>rotate</a>
									</li>
									<li>
										<button class="changeuploaded" tabindex="1">
											<input type="file"
												(change)="vehicleOfficialImagesChange($event,'DriverLicense',DriverLicenseId)"
												tabindex="-1" accept="image/jpeg, image/png">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Change
										</button>
									</li>
									<li><a class="delete-btn" (click)="deleteImage(DriverLicenseId,'DriverLicense')"
											tabindex="1">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path
													d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
											</svg>
											Delete</a></li>
								</ul>
							</div>
						</div>
					</div>
					<ng-template #noDriverLicense>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<div>
									<p [class.ng-invalid]="submittedForm && f.DriverLicense.errors.required">Driver /
										Chauffeur License <span class="optionalTextStyle">(Front) </span> <span
											class="asterisk">*</span></p>
									<div class="emptyImage">
										<img src="../../../../assets/images/empty-image.png" tabindex="-1">
									</div>
									<ul class="mt-3">
										<li>
											<button class="adduploaded" tabindex="1">
												<input type="file"
													(change)="vehicleOfficialImagesChange($event,'DriverLicense',DriverLicenseId)"
													tabindex="-1" accept="image/jpeg, image/png">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0V0z" />
													<circle cx="12" cy="12" r="3" />
													<path
														d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
												</svg>
												Add
											</button>
										</li>
									</ul>
								</div>
								<div *ngIf="submittedForm && f.DriverLicense.errors"
									class="text-danger margin-bottom-thin my-mat-hint text-center mb-4">
									<div *ngIf="f.DriverLicense.errors.required">The Driver / Chauffeur License (Front)
										is
										required.</div>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
			<hr>
			<div class="vehtype_extintwrap single-image" [hidden]="affiliateType!='gig_operator'">
				<div class="row">
					<div class="col-md-4" *ngIf="StarRating; else noStarRating">
						<div class="vehtype_extint">
							<div>
								<p>Star Rating (Min 4.5) </p>
								<img src="{{StarRating}}" (click)="showImageInModal(StarRating)" tabindex="-1">
								<ul class="mt-3">
									<li>
										<a class="custom-arrow-clockwise-button"
											(click)="fetchImageBlob(StarRating ,'StarRating',StarRatingId)">
											<i class="bi bi-arrow-clockwise"></i>rotate</a>
									</li>
									<li>
										<button class="changeuploaded" tabindex="1">
											<input type="file"
												(change)="vehicleOfficialImagesChange($event,'StarRating',StarRatingId)"
												tabindex="-1" accept="image/jpeg, image/png">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Change
										</button>
									</li>
									<li><a class="delete-btn" (click)="deleteImage(StarRatingId,'StarRating')"
											tabindex="1">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path
													d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
											</svg>
											Delete</a></li>
								</ul>
							</div>
						</div>
					</div>
					<ng-template #noStarRating>
						<div class="col-md-4" [hidden]="affiliateType!='gig_operator'">
							<div class="vehtype_extint">
								<div>
									<p>Star Rating (Min 4.5) </p>
									<div class="emptyImage">
										<img src="../../../../assets/images/empty-image.png" tabindex="-1">
									</div>
									<ul class="mt-3">
										<li>
											<button class="adduploaded" tabindex="1">
												<input type="file"
													(change)="vehicleOfficialImagesChange($event,'StarRating',StarRatingId)"
													tabindex="-1" accept="image/jpeg, image/png">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0V0z" />
													<circle cx="12" cy="12" r="3" />
													<path
														d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
												</svg>
												Add
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
				<div class="row">
					<div class="col-md-4 mb-3">
						<mat-form-field appearance="outline">
							<mat-label>Star Rating Value</mat-label>
							<mat-select formControlName="starRatingValue" tabindex="1">
								<mat-option value="">Choose Star Rating Value</mat-option>
								<mat-option value="4.5">4.5</mat-option>
								<mat-option value="4.6">4.6</mat-option>
								<mat-option value="4.7">4.7</mat-option>
								<mat-option value="4.8">4.8</mat-option>
								<mat-option value="4.9">4.9</mat-option>
								<mat-option value="5.0">5.0</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</div>
			<hr>
			<div class="row custom-check-row toggleLabelClass">
				<div class="col-12">
					<h5 class="choose-all my-3">Choose all that apply <span class="spanH5">(Optional)</span>
					</h5>
				</div>
				<div class="col-12">
					<div class="custom-control custom-checkbox">
						<mat-slide-toggle id="veteran" class="example-margin" [color]="color" [checked]="VeteranCheck"
							(change)="onChildVeteranDoDChange($event.checked,'Veteran'); globalFunctions.scrollIntoView(veterantarget)"
							tabindex="1">
						</mat-slide-toggle>
						<label class="ml-3" for="veteran">Veteran</label>
					</div>
				</div>
				<div class="col-12" [hidden]="!VeteranCheck" #veterantarget>
					<div class="vehtype_extintwrap single-image">
						<div class="row">
							<div class="col-md-4" *ngIf="VeteranIdCard; else noVeteranIdCard">
								<div class="vehtype_extint">
									<div>
										<p>Veteran's ID Card <span class="asterisk">*</span></p>
										<img src="{{VeteranIdCard}}" (click)="showImageInModal(VeteranIdCard)"
											tabindex="-1">
										<ul class="mt-3">
											<li>
												<a class="custom-arrow-clockwise-button"
													(click)="fetchImageBlob(VeteranIdCard ,'VeteranIdCard',VeteranIdCardId)">
													<i class="bi bi-arrow-clockwise"></i>rotate</a>
											</li>
											<li>
												<button class="changeuploaded" tabindex="1">
													<input type="file"
														(change)="vehicleOfficialImagesChange($event,'VeteranIdCard',VeteranIdCardId)"
														tabindex="-1" accept="image/jpeg, image/png">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
														<path fill="none" d="M0 0h24v24H0V0z" />
														<circle cx="12" cy="12" r="3" />
														<path
															d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
													</svg>
													Change
												</button>
											</li>
											<li><a class="delete-btn"
													(click)="deleteImage(VeteranIdCardId,'VeteranIdCard')" tabindex="1">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
														<path
															d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
													</svg>
													Delete</a></li>
										</ul>
									</div>
								</div>
							</div>
							<ng-template #noVeteranIdCard>
								<div class="col-md-4">
									<div class="vehtype_extint">
										<div>
											<div *ngIf="f.VeteranIdCard.errors">
												<p
													[class.ng-invalid]="submittedForm && f.VeteranIdCard.errors.required">
													Veteran's ID Card <span class="asterisk">*</span></p>
											</div>
											<div class="emptyImage">
												<img src="../../../../assets/images/empty-image.png" tabindex="-1">
											</div>
											<ul class="mt-3">
												<li>
													<button class="adduploaded" tabindex="1">
														<input type="file"
															(change)="vehicleOfficialImagesChange($event,'VeteranIdCard',VeteranIdCardId)"
															tabindex="-1" accept="image/jpeg, image/png">
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
															<path fill="none" d="M0 0h24v24H0V0z" />
															<circle cx="12" cy="12" r="3" />
															<path
																d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
														</svg>
														Add
													</button>
												</li>
											</ul>
										</div>
										<div *ngIf="submittedForm && f.VeteranIdCard.errors"
											class="text-danger margin-bottom-thin my-mat-hint text-center mb-4">
											<div *ngIf="f.VeteranIdCard.errors.required">The Veteran's ID Card Image is
												required.</div>
										</div>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
					<hr>
				</div>

				<div class="col-12" id="dodimage">
					<div class="custom-control custom-checkbox">
						<mat-slide-toggle class="example-margin" [color]="color" [checked]="DoDCheck"
							(change)="onChildVeteranDoDChange($event.checked,'DoD'); globalFunctions.scrollIntoView(dodtarget)"
							tabindex="1">
						</mat-slide-toggle>
						<label class="ml-3" for="dod">Dod Clearance</label>
					</div>
				</div>
				<div class="col-12" [hidden]="!DoDCheck" #dodtarget>
					<div class="vehtype_extintwrap single-image">
						<div class="row">
							<div class="col-md-4" *ngIf="DoDImage; else noDoDImage">
								<div class="vehtype_extint">
									<div>
										<p>DoD Image <span class="asterisk">*</span></p>
										<img src="{{DoDImage}}" (click)="showImageInModal(DoDImage)" tabindex="-1">
										<li>
											<a class="custom-arrow-clockwise-button"
												(click)="fetchImageBlob(DoDImage ,'DoDImage',DoDImageId)">
												<i class="bi bi-arrow-clockwise"></i>rotate</a>
										</li>
										<ul class="mt-3">
											<li>
												<button class="changeuploaded" tabindex="1">
													<input type="file"
														(change)="vehicleOfficialImagesChange($event,'DoDImage',DoDImageId)"
														tabindex="-1" accept="image/jpeg, image/png">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
														<path fill="none" d="M0 0h24v24H0V0z" />
														<circle cx="12" cy="12" r="3" />
														<path
															d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
													</svg>
													Change
												</button>
											</li>
											<li><a class="delete-btn" (click)="deleteImage(DoDImageId,'DoDImage')"
													tabindex="1">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
														<path
															d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
													</svg>
													Delete</a></li>
										</ul>
									</div>
								</div>
							</div>
							<ng-template #noDoDImage>
								<div class="col-md-4">
									<div class="vehtype_extint">
										<div>
											<div *ngIf="f.DoDImage.errors">
												<p [class.ng-invalid]="submittedForm && f.DoDImage.errors.required">DoD
													Image
													<span class="asterisk">*</span>
												</p>
											</div>
											<div class="emptyImage">
												<img src="../../../../assets/images/empty-image.png" tabindex="-1">
											</div>
											<ul class="mt-3">
												<li>
													<button class="adduploaded" tabindex="1">
														<input type="file"
															(change)="vehicleOfficialImagesChange($event,'DoDImage',DoDImageId)"
															tabindex="-1" accept="image/jpeg, image/png">
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
															<path fill="none" d="M0 0h24v24H0V0z" />
															<circle cx="12" cy="12" r="3" />
															<path
																d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
														</svg>
														Add
													</button>
												</li>
											</ul>
										</div>
										<div *ngIf="submittedForm && f.DoDImage.errors"
											class="text-danger margin-bottom-thin my-mat-hint text-center mb-4">
											<div *ngIf="f.DoDImage.errors.required">The DoD Image is required.</div>
										</div>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
					<hr>
				</div>

				<div class="col-12">
					<div class="custom-control custom-checkbox">
						<mat-slide-toggle class="example-margin" [color]="color" [checked]="FoidCheck"
							(change)="onChildVeteranDoDChange($event.checked,'Foid'); globalFunctions.scrollIntoView(guntarget)"
							tabindex="1">
						</mat-slide-toggle>
						<label class="ml-3" for="foid">FOID (Gun) Card - <span class="optionalTextStyle">(Only 5 USA
								States
								Allow)</span> </label>
					</div>
				</div>
				<div #guntarget class="col-12" [hidden]="!FoidCheck">
					<div class="vehtype_extintwrap single-image">
						<div class="row">
							<div class="col-md-4" *ngIf="FoidCardImage; else noFoidCardImage">
								<div class="vehtype_extint">
									<div>
										<p>FOID (Gun) Card <span class="asterisk">*</span></p>
										<img src="{{FoidCardImage}}" (click)="showImageInModal(FoidCardImage)"
											tabindex="-1">
										<ul class="mt-3">
											<li>
												<a class="custom-arrow-clockwise-button"
													(click)="fetchImageBlob(FoidCardImage ,'FoidCardImage',FoidCardImageId)">
													<i class="bi bi-arrow-clockwise"></i>rotate</a>
											</li>
											<li>
												<button class="changeuploaded" tabindex="1">
													<input type="file"
														(change)="vehicleOfficialImagesChange($event,'FoidCardImage',FoidCardImageId)"
														tabindex="-1" accept="image/jpeg, image/png">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
														<path fill="none" d="M0 0h24v24H0V0z" />
														<circle cx="12" cy="12" r="3" />
														<path
															d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
													</svg>
													Change
												</button>
											</li>
											<li><a class="delete-btn"
													(click)="deleteImage(FoidCardImageId,'FoidCardImage')" tabindex="1">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
														<path
															d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
													</svg>
													Delete</a></li>
										</ul>
									</div>
								</div>
							</div>
							<ng-template #noFoidCardImage>
								<div class="col-md-4">
									<div class="vehtype_extint">
										<div>
											<div *ngIf="f.FoidCardImage.errors">
												<p
													[class.ng-invalid]="submittedForm && f.FoidCardImage.errors.required">
													FOID
													(Gun) Card <span class="asterisk">*</span></p>
											</div>
											<div class="emptyImage">
												<img src="../../../../assets/images/empty-image.png" tabindex="-1">
											</div>
											<ul class="mt-3">
												<li>
													<button class="adduploaded" tabindex="1">
														<input type="file"
															(change)="vehicleOfficialImagesChange($event,'FoidCardImage',FoidCardImageId)"
															tabindex="-1" accept="image/jpeg, image/png">
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
															<path fill="none" d="M0 0h24v24H0V0z" />
															<circle cx="12" cy="12" r="3" />
															<path
																d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
														</svg>
														Add
													</button>
												</li>
											</ul>
										</div>
										<div *ngIf="submittedForm && f.FoidCardImage.errors"
											class="text-danger margin-bottom-thin my-mat-hint text-center mb-4">
											<div *ngIf="f.FoidCardImage.errors.required">The FOID (Gun) Card Image is
												required.
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
					<hr>
				</div>

				<div class="col-12">
					<div class="custom-control custom-checkbox">
						<mat-slide-toggle class="example-margin" [checked]="SchoolBusCertifiedCheck"
							(change)="onChildVeteranDoDChange($event.checked,'SchoolBusCertified'); globalFunctions.scrollIntoView(childtarget)"
							tabindex="1">
						</mat-slide-toggle>
						<label class="ml-3" for="childSchoolBusCertified">Child/School Bus Certified</label>
					</div>
				</div>
				<div #childtarget class="col-12" [hidden]="!SchoolBusCertifiedCheck">
					<div class="vehtype_extintwrap single-image">
						<div class="row">
							<div class="col-md-4" *ngIf="schoolBusCertificateImage; else noSchoolBusCertificateImage">
								<div class="vehtype_extint">
									<div>
										<p>Child/School Bus Certified <span class="asterisk">*</span></p>
										<img src="{{schoolBusCertificateImage}}"
											(click)="showImageInModal(schoolBusCertificateImage)" tabindex="-1">
										<ul class="mt-3">
											<li>
												<a class="custom-arrow-clockwise-button"
													(click)="fetchImageBlob(schoolBusCertificateImage ,'schoolBusCertificateImage',schoolBusCertificateImageIdId)">
													<i class="bi bi-arrow-clockwise"></i>rotate</a>
											</li>
											<li>
												<button class="changeuploaded" tabindex="1">
													<input type="file"
														(change)="vehicleOfficialImagesChange($event,'schoolBusCertificateImage',schoolBusCertificateImageIdId)"
														tabindex="-1" accept="image/jpeg, image/png">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
														<path fill="none" d="M0 0h24v24H0V0z" />
														<circle cx="12" cy="12" r="3" />
														<path
															d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
													</svg>
													Change
												</button>
											</li>
											<li><a class="delete-btn"
													(click)="deleteImage(schoolBusCertificateImageIdId,'schoolBusCertificateImage')"
													tabindex="1">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
														<path
															d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
													</svg>
													Delete</a></li>
										</ul>
									</div>
								</div>
							</div>
							<ng-template #noSchoolBusCertificateImage>
								<div class="col-md-4">
									<div class="vehtype_extint">
										<div>
											<div *ngIf="f.schoolBusCertificateImage.errors">
												<p
													[class.ng-invalid]="submittedForm && f.schoolBusCertificateImage.errors.required">
													Child/School Bus Certified <span class="asterisk">*</span></p>
											</div>
											<div class="emptyImage">
												<img src="../../../../assets/images/empty-image.png" tabindex="-1">
											</div>
											<ul class="mt-3">
												<li>
													<button class="adduploaded" tabindex="1">
														<input type="file"
															(change)="vehicleOfficialImagesChange($event,'schoolBusCertificateImage',schoolBusCertificateImageIdId)"
															tabindex="-1" accept="image/jpeg, image/png">
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
															<path fill="none" d="M0 0h24v24H0V0z" />
															<circle cx="12" cy="12" r="3" />
															<path
																d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
														</svg>
														Add
													</button>
												</li>
											</ul>
										</div>
										<div *ngIf="submittedForm && f.schoolBusCertificateImage.errors"
											class="text-danger margin-bottom-thin my-mat-hint text-center mb-4">
											<div *ngIf="f.schoolBusCertificateImage.errors.required">Child/School Bus
												Certified is
												required.
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
					<hr>
				</div>

				<div class="col-12">
					<div class="custom-control custom-checkbox">
						<mat-slide-toggle class="example-margin" [color]="color" [checked]="BackgroundCertificateCheck"
							(change)="onChildVeteranDoDChange($event.checked,'BackgroundCertificate'); globalFunctions.scrollIntoView(backgroundtarget)"
							tabindex="1">
						</mat-slide-toggle>
						<label class="ml-3" for="backgroundCertificate">Background Certified</label>
					</div>
				</div>
				<div class="col-12" [hidden]="!BackgroundCertificateCheck" #backgroundtarget>
					<div class="vehtype_extintwrap single-image">
						<div class="row">
							<div class="col-md-4" *ngIf="BackgroundCheckerID; else noBackgroundCheckerID">
								<div class="vehtype_extint">
									<div>
										<p>Background ID Image</p>
										<img src="{{BackgroundCheckerID}}"
											(click)="showImageInModal(BackgroundCheckerID)" tabindex="-1">
										<ul class="mt-3 mb-0">
											<li>
												<a class="custom-arrow-clockwise-button"
													(click)="fetchImageBlob(BackgroundCheckerID ,'BackgroundCheckerID',BackgroundCheckerIDId)">
													<i class="bi bi-arrow-clockwise"></i>rotate</a>
											</li>
											<li>
												<button class="changeuploaded" tabindex="1">
													<input type="file"
														(change)="vehicleOfficialImagesChange($event,'BackgroundCheckerID',BackgroundCheckerIDId)"
														tabindex="-1" accept="image/jpeg, image/png">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
														<path fill="none" d="M0 0h24v24H0V0z" />
														<circle cx="12" cy="12" r="3" />
														<path
															d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
													</svg>
													Change
												</button>
											</li>
											<li><a class="delete-btn"
													(click)="deleteImage(BackgroundCheckerIDId,'BackgroundCheckerID')"
													tabindex="1">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
														<path
															d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
													</svg>
													Delete</a></li>
										</ul>
									</div>
								</div>
							</div>
							<ng-template #noBackgroundCheckerID>
								<div class="col-md-4">
									<div class="vehtype_extint">
										<div>
											<p>Background ID Image</p>
											<div class="emptyImage">
												<img src="../../../../assets/images/empty-image.png" tabindex="-1">
											</div>
											<ul class="mt-3 mb-0">
												<li>
													<button class="adduploaded" tabindex="1">
														<input type="file"
															(change)="vehicleOfficialImagesChange($event,'BackgroundCheckerID','BackgroundCheckerIDId')"
															tabindex="-1" accept="image/jpeg, image/png">
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
															<path fill="none" d="M0 0h24v24H0V0z" />
															<circle cx="12" cy="12" r="3" />
															<path
																d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
														</svg>
														Add
													</button>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
					<div class="row mb-4">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Background Comapny Name <span class="asterisk">*</span></mat-label>
								<input matInput placeholder="Background Comapny Name" formControlName="CheckerID"
									tabindex="1" (focus)="$event.target.select()">
							</mat-form-field>
							<div *ngIf="submittedForm && f.CheckerID.errors || f.CheckerID.touched && f.CheckerID.errors"
								class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.CheckerID.errors.required">This field is required</div>
							</div>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline" class="mat-custom-phone">
								<mat-label>Background company Telephone # <span class="asterisk">*</span></mat-label>
								<input matInput placeholder="Background company Telephone #"
									formControlName="BackgroundCompanyTelNumber" ng2TelInput
									[ng2TelInputOptions]="{'preferredCountries': ['us', 'ca','mx','gb']}"
									(countryChange)="onCountryChange($event,'BackgroundCompanyTelNumber')"
									(intlTelInputObject)="telInputObjectBackgroundCompanyTelNumber($event)" tabindex="1"
									(focus)="$event.target.select()" />
							</mat-form-field>
							<div *ngIf="submittedForm && f.BackgroundCompanyTelNumber.errors || f.BackgroundCompanyTelNumber.touched && f.BackgroundCompanyTelNumber.errors"
								class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.BackgroundCompanyTelNumber.errors.required">This field is required</div>
							</div>
							<div *ngIf="f.BackgroundCompanyTelNumber.errors"
								class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.BackgroundCompanyTelNumber.errors?.invalid_Pattern;else plusError">Do not
									add Dashes
									with numbers</div>
								<ng-template #plusError>
									<div *ngIf="f.BackgroundCompanyTelNumber.errors?.invalidPattern;else allErrors">Do
										not enter +
										sign</div>
									<ng-template #allErrors>
										<div *ngIf="f.BackgroundCompanyTelNumber.errors?.pattern;else numberLength">
											Enter Numbers Without Spaces or Dashes
										</div>
										<ng-template #numberLength>
											<div *ngIf="f.BackgroundCompanyTelNumber.errors.minlength">Minimum length
												should be 4</div>
											<div *ngIf="f.BackgroundCompanyTelNumber.errors.maxlength">Maximum length
												should be 15</div>
										</ng-template>
									</ng-template>
								</ng-template>
							</div>
						</div>
					</div>
					<hr>
				</div>

				<div class="col-12">
					<div class="custom-control custom-checkbox">
						<mat-slide-toggle class="example-margin" [color]="color" [checked]="Covid19VaccinationCheck"
							(change)="onChildVeteranDoDChange($event.checked,'Covid19Vaccination'); globalFunctions.scrollIntoView(covidtarget)"
							tabindex="1">
						</mat-slide-toggle>
						<label class="ml-3" for="Covid19Vaccination">COVID-19 Vaccination Record Card</label>
					</div>
				</div>
				<div class="col-12" [hidden]="!Covid19VaccinationCheck" #covidtarget>
					<div class="vehtype_extintwrap single-image">
						<div class="row">
							<div class="col-md-4" *ngIf="VaccinationCardImage; else noVaccinationCardImage">
								<div class="vehtype_extint">
									<div>
										<p>Vaccination Card Image</p>
										<img src="{{VaccinationCardImage}}"
											(click)="showImageInModal(VaccinationCardImage)" tabindex="-1">
										<ul class="mt-3">
											<li>
												<a class="custom-arrow-clockwise-button"
													(click)="fetchImageBlob(VaccinationCardImage ,'VaccinationCardImage',VaccinationCardImageId)">
													<i class="bi bi-arrow-clockwise"></i>rotate</a>
											</li>
											<li>
												<button class="changeuploaded" tabindex="1">
													<input type="file"
														(change)="vehicleOfficialImagesChange($event,'VaccinationCardImage',VaccinationCardImageId)"
														tabindex="-1" accept="image/jpeg, image/png">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
														<path fill="none" d="M0 0h24v24H0V0z" />
														<circle cx="12" cy="12" r="3" />
														<path
															d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
													</svg>
													Change
												</button>
											</li>
											<li><a class="delete-btn"
													(click)="deleteImage(VaccinationCardImageId,'VaccinationCardImage')"
													tabindex="1">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
														<path
															d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
													</svg>
													Delete</a></li>
										</ul>
									</div>
								</div>
							</div>
							<ng-template #noVaccinationCardImage>
								<div class="col-md-4">
									<div class="vehtype_extint">
										<div>
											<p>Vaccination Card Image</p>
											<div class="emptyImage">
												<img src="../../../../assets/images/empty-image.png" tabindex="-1">
											</div>
											<ul class="mt-3">
												<li>
													<button class="adduploaded" tabindex="1">
														<input type="file"
															(change)="vehicleOfficialImagesChange($event,'VaccinationCardImage','VaccinationCardImageId')"
															tabindex="-1" accept="image/jpeg, image/png">
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
															<path fill="none" d="M0 0h24v24H0V0z" />
															<circle cx="12" cy="12" r="3" />
															<path
																d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
														</svg>
														Add
													</button>
												</li>
											</ul>
										</div>
										<div *ngIf="submittedForm && f.VaccinationCardImage.errors"
											class="text-danger margin-bottom-thin my-mat-hint text-center">
											<div *ngIf="f.VaccinationCardImage.errors.required">The Vaccination Card
												Image is
												required.</div>
										</div>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
					<hr>
				</div>

				<div class="col-12">
					<div class="custom-control custom-checkbox">
						<mat-slide-toggle class="example-margin" [color]="color" [checked]="ExPoliceCheck"
							(change)="onChildVeteranDoDChange($event.checked,'ExPolice'); globalFunctions.scrollIntoView(policetarget)"
							tabindex="1">
						</mat-slide-toggle>
						<label class="ml-3" for="ExPolice">Ex / Police</label>
					</div>
				</div>
				<div class="col-12" [hidden]="!ExPoliceCheck" #policetarget>
					<div class="row mb-4">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Name of Department <span class="asterisk">*</span></mat-label>
								<input matInput placeholder="Name of Department" formControlName="LastPoliceDepartment"
									tabindex="1" (focus)="$event.target.select()">
							</mat-form-field>
							<div *ngIf="submittedForm && f.LastPoliceDepartment.errors || f.LastPoliceDepartment.touched && f.LastPoliceDepartment.errors"
								class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.LastPoliceDepartment.errors.required">This field is required</div>
							</div>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline" class="mat-custom-phone">
								<mat-label>Police Depart. Tel #</mat-label>
								<input matInput placeholder="Police Depart. Tel #"
									formControlName="PoliceForceTelephone" ng2TelInput
									[ng2TelInputOptions]="{'preferredCountries': ['us', 'ca','mx','gb']}"
									(countryChange)="onCountryChange($event,'PoliceForceTelephone')"
									(intlTelInputObject)="telInputObjectPoliceForceTelephone($event)" tabindex="1"
									(focus)="$event.target.select()" />
							</mat-form-field>
							<div *ngIf="f.PoliceForceTelephone.errors"
								class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.PoliceForceTelephone.errors?.invalid_Pattern;else plusError">Do not add
									Dashes
									with numbers</div>
								<ng-template #plusError>
									<div *ngIf="f.PoliceForceTelephone.errors?.invalidPattern;else allErrors">Do not
										enter +
										sign</div>
									<ng-template #allErrors>
										<div *ngIf="f.PoliceForceTelephone.errors?.pattern;else numberLength">Enter
											Numbers Without Spaces or Dashes
										</div>
										<ng-template #numberLength>
											<div *ngIf="f.PoliceForceTelephone.errors.minlength">Minimum length should
												be 4</div>
											<div *ngIf="f.PoliceForceTelephone.errors.maxlength">Maximum length should
												be 15</div>
										</ng-template>
									</ng-template>
								</ng-template>
							</div>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Country <span class="asterisk">*</span></mat-label>
								<mat-select formControlName="Country" (selectionChange)="changeCountry($event.value)"
									tabindex="1">
									<mat-option value="">Choose Country</mat-option>
									<mat-option *ngFor="let countryOption of countryOptions"
										[value]="countryOption.countryShortCode">{{countryOption.countryName}}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<div *ngIf="submittedForm && f.Country.errors || f.Country.touched && f.Country.errors"
								class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.Country.errors.required">This field is required</div>
							</div>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>State / Province <span class="asterisk">*</span></mat-label>
								<mat-select formControlName="State" tabindex="1">
									<mat-option value="">Choose State/Province</mat-option>
									<mat-option *ngFor="let stateOption of stateOptions"
										[value]="stateOption.shortCode">
										{{stateOption.name}}</mat-option>
								</mat-select>
							</mat-form-field>
							<div *ngIf="submittedForm && f.State.errors || f.State.touched && f.State.errors"
								class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.State.errors.required">This field is required</div>
							</div>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>City <span class="asterisk">*</span></mat-label>
								<input matInput placeholder="City" formControlName="City" tabindex="1"
									(focus)="$event.target.select()">
							</mat-form-field>
							<div *ngIf="submittedForm && f.City.errors || f.City.touched && f.City.errors"
								class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.City.errors.required">This field is required</div>
							</div>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Zip Code / Country Address Code <span class="asterisk">*</span></mat-label>
								<input matInput placeholder="Zip Code / Country Address Code" formControlName="ZipCode"
									tabindex="1" (focus)="$event.target.select()">
							</mat-form-field>
							<div *ngIf="submittedForm && f.ZipCode.errors || f.ZipCode.touched && f.ZipCode.errors"
								class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.ZipCode.errors.required">This field is required</div>
							</div>
							<div *ngIf="f.ZipCode.errors" class="text-danger margin-bottom-thin my-mat-hint">
								<div *ngIf="f.ZipCode.errors?.pattern">Enter Numbers Only
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="container">
		<div class="col-md-12">
			<div class="billing_inforamtion">
				<ul class="d-flex justify-content-center">
					<li>
						<button class="custom-filled-back-button" type="button" (click)="backButton()"
							data-toggle="tooltip" data-placement="top" title="Clicking back button deletes all fields."
							tabindex="1">
							<svg width="41" height="16" viewBox="0 0 41 16" fill="#fff"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M37.963 0.500001H0.5L0.5 2H19.7407H36.963C37.7914 2 38.463 2.67157 38.463 3.5V9C38.463 9.82843 37.7914 10.5 36.963 10.5H19.7407H19.2407V10V6.59305L14.4313 10.75L19.2407 14.907V13V12.5H19.7407H37.963C38.6378 12.5 39.2838 12.2351 39.7591 11.7656C40.2342 11.2963 40.5 10.661 40.5 10V2.5C40.5 1.82723 40.2315 1.35011 39.8074 1.0275C39.3675 0.692862 38.7244 0.500001 37.963 0.500001Z"
									fill="#fff" stroke="#fff" />
							</svg></button>
					</li>
					<li><button class="custom-filled-reset-button" (click)="resetForm()" tabindex="1">RESET</button>
					</li>
					<li><button class="custom-filled-button" (click)="submitForm()" [disabled]="disableSubmitButton"
							tabindex="1">SAVE</button></li>
				</ul>
			</div>
		</div>
	</div>

</form>

<!-- Image modal -->
<app-image-modal (closeTab)="closeButton()" [modalImage]="modalImage"></app-image-modal>