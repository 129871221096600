<app-header></app-header>
<!-- AGM Map -->
<div class="container py-3">
	<div class="row">
		<div class="col-md-12">
			<button class="custom-filled-back-button" [routerLink]="['/admin/daily-bookings-admin']">
				<svg width="41" height="16" viewBox="0 0 41 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M37.963 0.500001H0.5L0.5 2H19.7407H36.963C37.7914 2 38.463 2.67157 38.463 3.5V9C38.463 9.82843 37.7914 10.5 36.963 10.5H19.7407H19.2407V10V6.59305L14.4313 10.75L19.2407 14.907V13V12.5H19.7407H37.963C38.6378 12.5 39.2838 12.2351 39.7591 11.7656C40.2342 11.2963 40.5 10.661 40.5 10V2.5C40.5 1.82723 40.2315 1.35011 39.8074 1.0275C39.3675 0.692862 38.7244 0.500001 37.963 0.500001Z"
						fill="#fff" stroke="#fff" />
				</svg>
			</button>
		</div>
	</div>
	<div class="row mt-3">
		<div class="col-md-12">
			<div class="map-block">
				<div class="google_map">
					<div id="map" class="map"></div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>
<!-- AGM Map Ends -->