<ngx-spinner></ngx-spinner>
<main>
	<!-- Main page content-->
	<section class="adminbox_shadow user_information" id="daily_bookings_table">
		<div class="row">
			<div class="col-md-7">
				<h2 class="headinginfo">Farm out Bookings</h2>
			</div>
		</div>
		<div class="containeredd mt-1 ">
			<div class="row my-2 align-items-center">
				<!-- Start-Date -->
				<div class="horizontal-scroll-container">
					<div class="col-6 col-md-4">
						<mat-form-field appearance="outline" class="text-xl scroll-item">
							<mat-label>
								Start Date <span class="asterisk"> * </span>
							</mat-label>
							<input matInput [setOutputDateFormat]="outputDateFormat" [matDatepicker]="picker1"
								placeholder="Start Date" id="id_4"
								(dateChangeWithFormat)="changeDate('startDate', $event)" (click)="picker1.open()"
								[value]="startDate" [max]="endDate" readonly />
							<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker #picker1 [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
						</mat-form-field>
						<!-- <mat-form-field appearance="outline">
							<mat-label>Start date <span class="asterisk"> * </span></mat-label>
							<input matInput [matDatepicker]="picker3">
							<mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
							<mat-datepicker #picker3 [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
						  </mat-form-field> -->
					</div>

					<!-- End-Date -->
					<div class="col-6 col-md-4">
						<mat-form-field appearance="outline" class="text-xl scroll-item">
							<mat-label>
								End Date <span class="asterisk"> * </span>
							</mat-label>
							<input matInput [setOutputDateFormat]="outputDateFormat" [matDatepicker]="picker2"
								placeholder="End Date" id="id_5" (dateChangeWithFormat)="changeDate('endDate',$event)"
								(click)="picker2.open()" [value]="endDate" [min]="startDate" readonly />
							<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker #picker2 [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
						</mat-form-field>
						<!-- <mat-form-field appearance="outline">
							<mat-label>End date <span class="asterisk"> * </span></mat-label>
							<input matInput [matDatepicker]="picker4">
							<mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
							<mat-datepicker #picker4 [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
						  </mat-form-field> -->
					</div>
				</div>
				<!-- Search Button -->
				<div class="align-btn d-flex justify-content-around" style="margin-top: 15px;">
					<div class="col-sm-2 ml-n5">
						<button class="custom-filled-button orange-button"
							style="color:#fff;padding: 5px 12px !important;"
							(click)="loadBookings(null, startDate, endDate, searchText); saveCookie('admin_startDate_farmout', startDate); saveCookie('admin_endDate_farmout', endDate)">
							Search
						</button>
					</div>
					<div class="col-sm-2">
						<button class="custom-filled-back-button"
							style="white-space: nowrap;color:#fff;padding: 5px 12px !important; background-color: #000 !important;"
							(click)="reset(); loadBookings(null, startDate, endDate, searchText)">
							Reset
						</button>
					</div>
				</div>
			</div>
			<div class="row d-flex justify-content-between">
				<div class="col-md-12">
					<p><mat-checkbox (change)="handleChangeCheckbox($event.checked)" [checked]="useDateFilter">
							{{useDateFilter ? 'Uncheck to see current and future bookings' :
							'Check to see bookings for 7 days or based upon dates and search by any term.' }}
						</mat-checkbox></p>
				</div>

				<!-- <div class="col-md-6 sortCheckBox"> -->
				<!-- <p><mat-checkbox (change)="handleCheckboxSort($event.checked,'use_pickup_date')"
							[checked]="use_pickup_date">Order by pickup date</mat-checkbox></p> -->
				<!-- <p><mat-checkbox (change)="handleCheckboxSort($event.checked)" [checked]="use_created_at">Order by
							Booking id</mat-checkbox></p>
				</div> -->
			</div>
			<div class="row my-2 mb-3">
				<!-- <div class="col-md-3">
						<div id="filter-type">
							<mat-form-field appearance="outline" class="m-0">
								<mat-label>
									Filter Type
								</mat-label>
								<mat-select [value]="filtertype" (selectionChange)="changeFilterType($event.value)">
									<mat-option value="bookingid"> Booking Id </mat-option>
									<mat-option value="paxname"> Pax Name </mat-option>
									<mat-option value="paxphone"> Pax Phone </mat-option>
									<mat-option value="drivername"> Driver Name </mat-option>
									<mat-option value="driverphone"> Driver Phone </mat-option>
									<mat-option value="pickup"> Pickup </mat-option>
									<mat-option value="dropoff"> Dropoff </mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div> -->
				<div class="col-md-12">
					<div id="search-field">
						<mat-form-field appearance="outline" class="m-0">
							<input matInput type="text" (keypress)="handleKeypressEvents()"
								(focus)="$event.target.select()" (keyup)="searchInBookings($event.target.value)"
								[value]="searchText"
								placeholder="Search by booking number, status, name, cell phone, vehicle type"
								id="keyword" autofocus>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<div class="containeredd driverside_table">
			<div class="row">
				<div class="col-md-12 custom_table">
					<div class="table-responsive" style="min-height: 480px" *ngIf="!noError">
						<table class="table table-dark table-striped table-bordered dataTable dt-responsive"
							id="dataTable" role="grid" aria-describedby="dataTable_info" cellspacing="0">
							<thead>
								<tr role="row">
									<th>Actions</th>
									<th>Booking</th>
									<th>Total</th>
									<th>Status</th>
									<th>Date</th>
									<th>Pax</th>
									<th>Driver</th>
									<th>Booking Type</th>
									<th>Vehicle</th>
									<!-- <th>Status</th> -->
								</tr>
							</thead>
							<tbody>
								<tr role="row" class="odd" *ngFor="let booking of bookings">

									<td>
										<a class="user-profileded view-button btn-transparent-dark dropdown-toggle font-weight-bold"
											href="javascript:void(0);" role="button" data-toggle="dropdown"
											aria-haspopup="true" aria-expanded="false">
											<span>View</span>
										</a>
										<div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
											aria-labelledby="navbarDropdownUserImage"
											style="min-height: 200px;max-height: 480px;overflow-y: scroll; z-index: 2;">
											<a class="dropdown-item" [routerLink]="['/admin/new-booking']"
												*ngIf="((currentUser?.roleName == 'admin' || subModules.includes('edit_booking')) && (booking?.booking_status != 'paid' && booking?.booking_status != 'paid_cash'))"
												[queryParams]="{bookingId: booking.booking_id, updateType: 'edit'}">
												<div class="dropdown-item-icon"></div>
												Edit / Change
											</a>
											<a class="dropdown-item" [routerLink]="['/admin/finalize-booking']"
												[queryParams]="{bookingId: booking.booking_id}"
												*ngIf="((currentUser?.roleName == 'admin' || subModules.includes('edit_booking')) && (booking?.booking_status != 'paid' && booking?.booking_status != 'paid_cash'))">
												<!-- *ngIf="booking.payment_status!='paid'" -->
												<div class="dropdown-item-icon"></div>
												Finalize
											</a>
											<a class="dropdown-item" (click)="invoiceAction(booking?.booking_id)"
												*ngIf="booking?.payment_status=='paid' || booking?.payment_status == 'paid_cash'">
												<div class="dropdown-item-icon"></div>
												Invoice
											</a>
											<a class="dropdown-item"
												(click)="sendEmailClicked(booking.booking_id,'emailPassenger')"
												data-toggle="modal" data-target="#emailPassenger"
												*ngIf="currentUser?.roleName == 'admin' || subModules.includes('email_passenger')">
												<div class="dropdown-item-icon"></div>
												Email Passenger
											</a>
											<a class="dropdown-item" (click)="auditTrail(booking.booking_id)"
												data-toggle="modal" data-target="#AuditTrailModal">
												<!-- *ngIf="booking.payment_status!='paid'" -->
												<div class="dropdown-item-icon"></div>
												Audit Trial
											</a>

										</div>
									</td>
									<td class="text-center font-weight-bolder cursor-pointer " data-toggle="modal"
										data-target="#previewBookingOnID"
										(click)="showBookingPreviewModal(booking?.booking_id)">
										<p class="booking-id-font font-weight-bolder cursor-pointer">
											<span class="text-underline"
												[innerHTML]="highlighText(booking?.booking_id )"
												style="font-weight: 600;"></span>
										</p>
									</td>
									<td class="text-center font-weight-bolder cursor-pointer">
										<p class="booking-id-font font-weight-bolder cursor-pointer">
											<span class="currency">{{booking?.currency_symbol}}</span><span
												[innerHTML]="highlighText(booking.grand_total ? booking.grand_total : 0 )"></span>
										</p>
									</td>
									<td>
										<div
											[className]="'badge badge-pill booking_status cursor-pointer ' + booking?.booking_status">
											{{booking?.booking_status | titlecase}}
										</div>
									</td>
									<td class="sorting_1">
										<p style="font-weight: 600 !important;">{{ FormatDate(booking.pickup_date) }}
										</p>
										<p style="font-weight: 600 !important;">{{ FormatTime(booking.pickup_time) }}
										</p>
										<p style="font-weight: 600 !important;">{{booking?.pickup_day}}</p>
									</td>
									<td>
										<p data-target="#sendEmailModal" style="white-space: pre" data-toggle="modal"
										(click)="openModal(booking, 'Passenger')" id="box">
										<span [innerHTML]="highlighText(booking?.passenger_name)"></span> |
										<span class="font-weight-bolder"
											[innerHTML]="highlighText( booking?.pax_tel)"></span>
									</p>
										<span class="loc-logo"
											(click)="showLocationPointOnMap(booking.booking_id, 'pickup')">
											<i class="bi bi-geo-alt-fill pin-icon-preview"></i>&nbsp;&nbsp;</span>
										<span class="text-underline cursor-pointer">
											<span [innerHTML]="highlighText(booking.pickup_address)"></span>
										</span>

									</td>
									<td>
										<p data-target="#sendEmailModal" data-toggle="modal"
											(click)="openModal(booking, 'Driver')"
											*ngIf="booking?.affiliate_type != 'gig_operator'; else gigOperator">
											<span class="text-capitalize"
												[innerHTML]="highlighText(booking?.driver_name.split(' ')[0] + ' ' + booking?.driver_name.split(' ')[1].slice(0,1))"></span>&nbsp;|
											<span class="font-weight-bolder"
												[innerHTML]="highlighText(booking?.driver_cell_isd + booking?.driver_tel)"></span>

										</p>
										<span class="loc-logo"
											(click)="showLocationPointOnMap(booking.booking_id, 'dropoff')">
											<i class="bi bi-geo-alt-fill pin-icon-preview"></i>&nbsp;&nbsp;</span>
										<span class="text-underline cursor-pointer">
											<span [innerHTML]="highlighText(booking.dropoff_address)"></span>
										</span>

									</td>
									<td>
										<span>
											{{ textFormatter(booking?.service_type) | titlecase }}
											/
											{{ textFormatter(booking?.transfer_type) | titlecase }}
										</span>
									</td>
									<td>
										<span [innerHTML]="highlighText(booking.vehicle_cat_name)"></span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p class="text-danger text-lg" *ngIf="noError">
						No Results Found.
					</p>
				</div>
				<!----PAGINATION---->
				<!----BACK BTN---->
				<nav aria-label="Page navigation example" *ngIf="totalRecords > 15">
					<ul class="pagination pagination-circle pg-blue">
						<!-- <li *ngIf="currentPage!=1" class="page-item disabled"
								(click)="loadBookings(firstPageUrl, startDate, endDate, searchText, filtertype)">
								<a class="page-link">First</a>
							</li>
							<li *ngIf="currentPage!=1" class="page-item disabled"
								(click)="loadBookings(prevPageUrl, startDate, endDate, searchText, filtertype)">
								<a class="page-link" aria-label="Previous">
									<span aria-hidden="true">&laquo;</span>
									<span class="sr-only">Previous</span>
								</a>
							</li>
							<li *ngFor="let in of counter() ;let i = index"
								[ngClass]="(in==currentPage)?'page-item active':'page-item'"
								(click)="loadBookings(path+'?page='+in, startDate, endDate, searchText, filtertype)"
								[disabled]="(in==currentPage)?true:false">
								<a class="page-link">{{in}}</a>
							</li>
							<li *ngIf="currentPage!=lastPage" class="page-item"
								(click)="loadBookings(nextPageUrl, startDate, endDate, searchText, filtertype)">
								<a class="page-link" aria-label="Next">
									<span aria-hidden="true">&raquo;</span>
									<span class="sr-only">Next</span>
								</a>
							</li>
							<li *ngIf="currentPage!=lastPage" class="page-item"
								(click)="loadBookings(lastPageUrl, startDate, endDate, searchText, filtertype)">
								<a class="page-link">Last</a>
							</li> -->
						<li class="page-item disabled" *ngIf="nextPageUrl"
							(click)="handleShowMore(nextPageUrl,startDate, endDate, searchText)">
							<a class="page-link">Show more</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</section>
</main>

<!-- repeat/return booking type modal -->
<div class="modal fade" id="change_status_booking_Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content user_inforamtion">
			<!-- <button type="button" class="close closeButton x-circle-btn" data-dismiss="modal" aria-label="Close">
				<i class="bi bi-x-circle"></i>
			</button> -->
			<div class="modal-header pb-0">
				<h3 class="mb-0" id="exampleModalLabel">Change Booking Status</h3>
			</div>
			<div class="modal-body">
				<form [formGroup]="changeStatusForm">
					<div class="form-group">
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label>Change Status</mat-label>
								<mat-select #select formControlName="booking_status">
									<mat-option *ngFor="let item of status_list"
										[value]="item.value">{{item.status}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</form>
			</div>
			<div>
				<div class="d-flex justify-content-center">
					<button type="button" class="custom-btn button2" (click)="submitChangeStatusForm()">Change
						Status</button>
				</div>
				<div class="d-flex justify-content-end mx-2">
					<button type="button" class="x-close-btn x-circle-btn" data-dismiss="modal" aria-label="Close"
						style="background: none;">
						<i class="bi bi-x-circle"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- send email modal -->
<div class="modal fade" id="emailModal" tabindex="-1" role="dialog" aria-labelledby="emailModal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header pb-0">
				<h3 class="mb-0">Email</h3>
			</div>
			<div class="modal-body text-center">
				<p>Are you sure, You want to send email?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="custom-btn savebutton" (click)="emailForm()">Send
					Email</button>
				<button type="button" class="custom-btn resetbutton" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="emailPassenger" tabindex="-1" role="dialog" aria-labelledby="emailPassenger"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header pb-0">
				<h3 class="mb-0">Email</h3>
			</div>
			<div class="modal-body text-center">
				<p>Are you sure, You want to send email?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="custom-btn savebutton" (click)="emailPassenger()">Send
					Email</button>
				<button type="button" class="custom-btn resetbutton" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="emailAll" tabindex="-1" role="dialog" aria-labelledby="emailAll" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header pb-0">
				<h3 class="mb-0">Email</h3>
			</div>
			<div class="modal-body text-center">
				<p>Are you sure, You want to send email?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="custom-btn savebutton" (click)="emailAll()">Send
					Email</button>
				<button type="button" class="custom-btn resetbutton" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>

</div>
<div class="modal fade" id="updatedEmailAll" tabindex="-1" role="dialog" aria-labelledby="updatedEmailAll"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header pb-0">
				<h3 class="mb-0">Email</h3>
			</div>
			<div class="modal-body text-center">
				<p>Are you sure, You want to send email?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="custom-btn savebutton" (click)="updatedEmailAll()">Send
					Email</button>
				<button type="button" class="custom-btn resetbutton" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>

</div>


<!-- Audit trail Modal -->
<div class="modal fade bd-example-modal-lg" id="AuditTrailModal" tabindex="-1" role="dialog"
	aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content user_information bottom-container">
			<div class="modal-header pb-0">
				<h3 class="modal-title mb-0">Audit Trial</h3>
			</div>
			<div class="modal-body">
				<!-- {{invoiceData.audit_trail | json}} -->
				<table id="audittrail" class="table table-striped">
					<thead style="background-color: white;">
						<tr>
							<th scope="col">#</th>
							<th scope="col">Description</th>
							<th scope="col" id="date">Created Date</th>
						</tr>
					</thead>
					<tbody>

						<!-- <ng-container *ngIf="audit_Trail; else not_found"> -->
						<tr *ngFor="let record of audit_Trail; let i=index">
							<th scope="row">{{i+1}}</th>
							<td style="word-wrap: break-word;">{{record.message}}</td>
							<td>{{record.created_at}}</td>
						</tr>

						<!-- </ng-container> -->
						<!-- <ng-template #not_found>
							<tr>
								<td colspan = "3">No Record Found!</td>
							</tr>	
						</ng-template> -->


					</tbody>
				</table>
			</div>
			<div class="modal-footer justify-content-end">
				<button type="button" class="x-close-btn x-cross-btn mx-3" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" class="bi bi-x-circle"></span>
				</button>

			</div>
		</div>
	</div>
</div>

<!-- Accept charge modal -->
<div class="modal fade" id="accept_charge_modal" tabindex="-1" role="dialog" aria-labelledby="accept_charge_modal"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body text-center">
				<p style="font-size: 19px;font-weight: 400;">Are you sure, You want to charge 50% of this booking?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="custom-btn savebutton" (click)="accpetChargeAction()">Charge</button>
				<button type="button" class="custom-btn resetbutton" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>

<!-- charge back modal -->
<div class="modal fade" id="charge_back_modal" tabindex="-1" role="dialog" aria-labelledby="charge_back_modal"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body text-center">
				<p style="font-size: 19px;font-weight: 400;">Are you sure, You want to charge back for this booking?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="custom-btn savebutton" (click)="chargeBackAction()">Charge</button>
				<button type="button" class="custom-btn resetbutton" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>

<!-- send email and call modal -->
<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
	aria-hidden="true" data-keyboard="false">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content" style="background: #f5f4fe;">
			<div class="modal-header pb-0">
				<h3 class="modal-title mb-0">
					Booking # : {{ passengerDetails?.booking_id }}
				</h3>
			</div>
			<button type="button" (click)="closeModal()" class="close closeButton x-circle-btn m-2" data-dismiss="modal"
				aria-label="Close">
				<i class="bi bi-x-circle"></i>
			</button>
			<hr>
			<div class="text-center iconStyle">
				<div class="row">
					<div class="col-md-12">
						<h5 class="mb-5" *ngIf="passengerDetails?.selection_button == 'Driver'">
							Connect with {{ passengerDetails?.driver_name }} Via</h5>
						<h5 class="mb-5" *ngIf="passengerDetails?.selection_button == 'Passenger'">
							Connect with {{ passengerDetails?.passenger_name }} Via</h5>
						<h5 class="mb-5" *ngIf="passengerDetails?.selection_button == 'Loose_Affiliate'">
							Connect with {{ passengerDetails?.loose_affiliate_name }} Via</h5>
					</div>
					<div class="d-flex" style=" width: -webkit-fill-available;">
						<div class="col-md-4" (click)="messageField('Phone')">

							<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 122.88"
								style="enable-background:new 0 0 122.88 122.88" xml:space="preserve">
								<style type="text/css">
									.step0 {
										fill: #1A73E8;
									}

									.step1 {
										fill: #185ABC;
									}

									.step2 {
										fill: #FFFFFF;
									}

									.step3 {
										fill: #8AB4F8;
									}
								</style>
								<g>
									<path class="step0"
										d="M122.88,61.41C122.88,27.49,95.39,0,61.47,0S0.06,27.49,0.06,61.41s27.49,61.41,61.41,61.41 S122.88,95.32,122.88,61.41L122.88,61.41z" />
									<path class="step1"
										d="M83.82,87.93H41.97c-5.95,0-11.14-4.49-11.14-10.45v-0.06v3.48c0,5.95,5.19,11.14,11.14,11.14h41.85 c6.01,0,11.14-5.25,11.14-11.14v-3.48C94.96,83.38,89.77,87.93,83.82,87.93L83.82,87.93L83.82,87.93z M30.77,57.93v-4.75 l-7.79-12.85c-0.44-0.7-0.63-1.33-0.57-1.96v5.44c0,0.51,0.19,1.01,0.51,1.58L30.77,57.93L30.77,57.93z" />
									<path class="step2"
										d="M83.82,36.28H25.2c-2.34,0-3.67,2.03-2.09,4.18l7.66,13.29v23.74c0,6.08,4.43,11.14,10.45,11.14h42.54 c6.01,0,11.14-5.13,11.14-11.14V47.48C94.96,41.4,89.83,36.28,83.82,36.28L83.82,36.28z" />
									<path class="step3"
										d="M83.12,53.05H42.67c-1.77,0-3.48-1.08-3.48-2.79c0-1.71,1.71-2.79,3.48-2.79h40.45c1.77,0,3.48,1.08,3.48,2.79 C86.6,51.98,84.9,53.05,83.12,53.05L83.12,53.05L83.12,53.05z M83.12,64.19H42.67c-1.77,0-3.48-1.08-3.48-2.79 c0-1.71,1.71-2.79,3.48-2.79h40.45c1.77,0,3.48,1.08,3.48,2.79C86.6,63.12,84.9,64.19,83.12,64.19L83.12,64.19L83.12,64.19z M71.92,75.4H42.61c-1.77,0-3.48-1.08-3.48-2.79c0-1.71,1.71-2.79,3.48-2.79h29.31c1.77,0,3.48,1.08,3.48,2.79 C75.4,74.32,73.69,75.4,71.92,75.4L71.92,75.4z" />
									<path class="step1"
										d="M122.88,61.09c-0.13,26.91-17.66,49.76-41.85,57.86c-6.14,2.09-12.72,3.17-19.56,3.17 c-33.81,0-61.22-27.29-61.41-61.03v0.38c0,33.93,27.48,61.41,61.41,61.41c6.84,0,13.42-1.14,19.56-3.17 c24.31-8.17,41.85-31.15,41.85-58.24V61.09L122.88,61.09z" />
									<path class="step3"
										d="M80.97,3.17C74.83,1.08,68.25,0,61.41,0C27.48,0,0,27.48,0,61.41v0.38C0.19,28.04,27.6,0.76,61.41,0.76 c6.84,0,13.42,1.14,19.56,3.17c24.25,8.1,41.72,30.96,41.85,57.86v-0.38C122.88,34.31,105.34,11.33,80.97,3.17L80.97,3.17z" />
								</g>
							</svg>
							<h6 class="headingStyle">Send SMS</h6>
						</div>
						<div class="col-md-4">
							<a *ngIf="passengerDetails?.selection_button == 'Passenger'"
								href="tel:{{passengerDetails?.passenger_cell_isd}}{{passengerDetails?.passenger_cell}}">
								<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 122.88 122.88">
									<defs>
										<style>
											.phoneCls-1 {
												fill: #33a867;
												fill-rule: evenodd;
											}
										</style>
									</defs>
									<path class="phoneCls-1"
										d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0ZM48.1,56.19A49.35,49.35,0,0,0,55.39,66.4a41.25,41.25,0,0,0,11.55,8.51,1.14,1.14,0,0,0,1,0,4.48,4.48,0,0,0,1.39-1A16.91,16.91,0,0,0,70.6,72.4c1.85-2.44,4.15-5.47,7.39-4l.2.11L89,74.77l.11.07a4.84,4.84,0,0,1,2,4.2,11.43,11.43,0,0,1-1.58,5.37,11.06,11.06,0,0,1-5.2,4.6A23.63,23.63,0,0,1,78,90.75a21.69,21.69,0,0,1-9.8-.82,43.06,43.06,0,0,1-9.85-4.76L58.05,85c-1.6-1-3.33-2.07-5-3.33a62.69,62.69,0,0,1-16.65-18.9c-3.46-6.26-5.34-13-4.32-19.44a14,14,0,0,1,4.72-8.85A12.68,12.68,0,0,1,46.18,32a1.35,1.35,0,0,1,1.09.7L54.21,44.4a3.68,3.68,0,0,1,.59,3.92,7.71,7.71,0,0,1-2.66,3c-.37.32-.82.64-1.29,1-1.55,1.12-3.32,2.42-2.71,4l0-.05Z" />
								</svg>
								<h6 class="headingStyle">Make Call</h6>
							</a>
							<a *ngIf="passengerDetails?.selection_button == 'Driver'"
								href="tel:{{passengerDetails?.driver_cell_isd}}{{passengerDetails?.driver_cell_number}}">
								<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 122.88 122.88">
									<defs>
										<style>
											.phoneCls-1 {
												fill: #33a867;
												fill-rule: evenodd;
											}
										</style>
									</defs>
									<path class="phoneCls-1"
										d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0ZM48.1,56.19A49.35,49.35,0,0,0,55.39,66.4a41.25,41.25,0,0,0,11.55,8.51,1.14,1.14,0,0,0,1,0,4.48,4.48,0,0,0,1.39-1A16.91,16.91,0,0,0,70.6,72.4c1.85-2.44,4.15-5.47,7.39-4l.2.11L89,74.77l.11.07a4.84,4.84,0,0,1,2,4.2,11.43,11.43,0,0,1-1.58,5.37,11.06,11.06,0,0,1-5.2,4.6A23.63,23.63,0,0,1,78,90.75a21.69,21.69,0,0,1-9.8-.82,43.06,43.06,0,0,1-9.85-4.76L58.05,85c-1.6-1-3.33-2.07-5-3.33a62.69,62.69,0,0,1-16.65-18.9c-3.46-6.26-5.34-13-4.32-19.44a14,14,0,0,1,4.72-8.85A12.68,12.68,0,0,1,46.18,32a1.35,1.35,0,0,1,1.09.7L54.21,44.4a3.68,3.68,0,0,1,.59,3.92,7.71,7.71,0,0,1-2.66,3c-.37.32-.82.64-1.29,1-1.55,1.12-3.32,2.42-2.71,4l0-.05Z" />
								</svg>
								<h6 class="headingStyle">Make Call</h6>
							</a>
							<a *ngIf="passengerDetails?.selection_button == 'Loose_Affiliate'"
								href="tel:{{passengerDetails?.loose_affiliate_phone_isd}}{{passengerDetails?.loose_affiliate_phone}}">
								<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 122.88 122.88">
									<defs>
										<style>
											.phoneCls-1 {
												fill: #33a867;
												fill-rule: evenodd;
											}
										</style>
									</defs>
									<path class="phoneCls-1"
										d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0ZM48.1,56.19A49.35,49.35,0,0,0,55.39,66.4a41.25,41.25,0,0,0,11.55,8.51,1.14,1.14,0,0,0,1,0,4.48,4.48,0,0,0,1.39-1A16.91,16.91,0,0,0,70.6,72.4c1.85-2.44,4.15-5.47,7.39-4l.2.11L89,74.77l.11.07a4.84,4.84,0,0,1,2,4.2,11.43,11.43,0,0,1-1.58,5.37,11.06,11.06,0,0,1-5.2,4.6A23.63,23.63,0,0,1,78,90.75a21.69,21.69,0,0,1-9.8-.82,43.06,43.06,0,0,1-9.85-4.76L58.05,85c-1.6-1-3.33-2.07-5-3.33a62.69,62.69,0,0,1-16.65-18.9c-3.46-6.26-5.34-13-4.32-19.44a14,14,0,0,1,4.72-8.85A12.68,12.68,0,0,1,46.18,32a1.35,1.35,0,0,1,1.09.7L54.21,44.4a3.68,3.68,0,0,1,.59,3.92,7.71,7.71,0,0,1-2.66,3c-.37.32-.82.64-1.29,1-1.55,1.12-3.32,2.42-2.71,4l0-.05Z" />
								</svg>
								<h6 class="headingStyle">Make Call</h6>
							</a>
						</div>
						<div class="col-md-4 mailBoxStyle" (click)="messageField('Email')">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
								<linearGradient id="NvW_RwTAona1rlOp72mkqa" x1="24" x2="24" y1="42" y2="6.207"
									gradientUnits="userSpaceOnUse">
									<stop offset="0" stop-color="#1ac8fc" />
									<stop offset=".258" stop-color="#1bb6fa" />
									<stop offset=".775" stop-color="#1c86f5" />
									<stop offset="1" stop-color="#1d70f2" />
								</linearGradient>
								<path fill="url(#NvW_RwTAona1rlOp72mkqa)"
									d="M34,42H14c-4.411,0-8-3.589-8-8V14c0-4.411,3.589-8,8-8h20c4.411,0,8,3.589,8,8v20 C42,38.411,38.411,42,34,42z" />
								<path
									d="M34,16c0.178,0,0.347,0.031,0.512,0.074l-7.824,7.825C25.946,24.64,24.973,25.01,24,25.01 s-1.946-0.37-2.688-1.111l-7.824-7.825C13.653,16.031,13.822,16,14,16H34 M35.926,17.488C35.969,17.653,36,17.822,36,18v12 c0,0.178-0.031,0.347-0.074,0.512L29.414,24L35.926,17.488 M12.074,17.488L18.586,24l-6.511,6.511C12.031,30.347,12,30.178,12,30 V18C12,17.822,12.031,17.653,12.074,17.488 M27.99,25.404l6.522,6.522C34.347,31.969,34.178,32,34,32H14 c-0.178,0-0.347-0.031-0.512-0.074l6.522-6.522c1.117,1.063,2.55,1.605,3.99,1.605S26.873,26.467,27.99,25.404 M34,15H14 c-0.244,0-0.495,0.035-0.768,0.108c-0.346,0.092-0.616,0.361-0.709,0.707c-0.092,0.341,0.003,0.704,0.248,0.956 c-0.189-0.183-0.44-0.283-0.697-0.283c-0.087,0-0.174,0.011-0.26,0.034c-0.345,0.093-0.615,0.363-0.707,0.709 C11.035,17.505,11,17.756,11,18v12c0,0.244,0.035,0.495,0.108,0.768c0.092,0.346,0.361,0.616,0.707,0.709 c0.086,0.023,0.173,0.034,0.26,0.034c0.257,0,0.508-0.099,0.696-0.282c-0.245,0.253-0.34,0.616-0.248,0.956 c0.093,0.345,0.363,0.615,0.709,0.707C13.505,32.965,13.756,33,14,33h20c0.244,0,0.495-0.035,0.768-0.108 c0.346-0.092,0.616-0.361,0.709-0.707c0.092-0.341-0.003-0.704-0.248-0.956c0.189,0.183,0.44,0.282,0.696,0.282 c0.087,0,0.174-0.011,0.26-0.034c0.345-0.093,0.615-0.363,0.707-0.709C36.965,30.495,37,30.244,37,30V18 c0-0.244-0.035-0.495-0.108-0.768c-0.092-0.346-0.361-0.616-0.707-0.709c-0.086-0.023-0.173-0.034-0.26-0.034 c-0.257,0-0.508,0.099-0.697,0.282c0.245-0.253,0.34-0.616,0.248-0.956c-0.093-0.345-0.363-0.615-0.709-0.707 C34.495,15.035,34.244,15,34,15L34,15z"
									opacity=".05" />
								<path
									d="M34,15.5H14c-0.203,0-0.406,0.029-0.64,0.091c-0.173,0.046-0.308,0.181-0.354,0.353 c-0.046,0.173,0.003,0.357,0.129,0.483l7.824,7.825C21.77,25.063,22.85,25.51,24,25.51s2.23-0.447,3.041-1.257l7.824-7.825 c0.126-0.126,0.176-0.311,0.129-0.483c-0.046-0.173-0.182-0.307-0.354-0.353C34.406,15.529,34.203,15.5,34,15.5L34,15.5z M35.926,16.988c-0.131,0-0.259,0.051-0.354,0.146l-6.511,6.512c-0.195,0.195-0.195,0.512,0,0.707l6.511,6.511 c0.095,0.095,0.223,0.146,0.354,0.146c0.043,0,0.087-0.006,0.13-0.017c0.173-0.046,0.307-0.182,0.353-0.354 C36.471,30.406,36.5,30.203,36.5,30V18c0-0.203-0.029-0.406-0.091-0.64c-0.046-0.173-0.181-0.308-0.353-0.354 C36.013,16.994,35.969,16.988,35.926,16.988L35.926,16.988z M12.074,16.988c-0.043,0-0.087,0.006-0.13,0.017 c-0.173,0.046-0.307,0.182-0.353,0.354C11.529,17.594,11.5,17.797,11.5,18v12c0,0.203,0.029,0.406,0.091,0.64 c0.046,0.173,0.181,0.308,0.353,0.354c0.043,0.012,0.087,0.017,0.13,0.017c0.131,0,0.259-0.051,0.354-0.146l6.511-6.511 c0.195-0.195,0.195-0.512,0-0.707l-6.511-6.512C12.333,17.04,12.205,16.988,12.074,16.988L12.074,16.988z M27.99,24.904 c-0.124,0-0.248,0.046-0.345,0.138c-0.995,0.946-2.289,1.467-3.645,1.467s-2.651-0.521-3.645-1.467 c-0.097-0.092-0.221-0.138-0.345-0.138c-0.128,0-0.256,0.049-0.354,0.146l-6.522,6.522c-0.126,0.126-0.176,0.311-0.129,0.483 s0.182,0.307,0.354,0.353C13.594,32.471,13.797,32.5,14,32.5h20c0.203,0,0.406-0.029,0.64-0.091 c0.173-0.046,0.308-0.181,0.354-0.353s-0.003-0.357-0.129-0.483l-6.522-6.522C28.246,24.953,28.118,24.904,27.99,24.904 L27.99,24.904z"
									opacity=".07" />
								<path fill="#f9f9f9"
									d="M35.926,17.488L29.414,24l6.511,6.511C35.969,30.347,36,30.178,36,30V18 C36,17.822,35.969,17.653,35.926,17.488z M26.688,23.899l7.824-7.825C34.347,16.031,34.178,16,34,16H14 c-0.178,0-0.347,0.031-0.512,0.074l7.824,7.825C22.795,25.38,25.205,25.38,26.688,23.899z M12.074,17.488 C12.031,17.653,12,17.822,12,18v12c0,0.178,0.031,0.347,0.074,0.512L18.586,24L12.074,17.488z M24,27.009 c-1.44,0-2.873-0.542-3.99-1.605l-6.522,6.522C13.653,31.969,13.822,32,14,32h20c0.178,0,0.347-0.031,0.512-0.074l-6.522-6.522 C26.873,26.467,25.44,27.009,24,27.009z" />
							</svg>
							<h6>Send E-Mail</h6>
						</div>
					</div>

				</div>
				<div class="row" [hidden]="!show">
					<div class="col-md-12">
						<mat-form-field class="example-full-width" appearance="outline">
							<mat-label>
								{{ sendMessageField ? 'Send SMS' : 'Send Email' }}
							</mat-label>
							<textarea matInput placeholder="Write a Message...." id="clearField" cdkTextareaAutosize
								cdkAutosizeMaxRows="10" #autosize="cdkTextareaAutosize" #inputmsg
								(focus)="$event.target.select()" style="font-weight: 700;"></textarea>
						</mat-form-field>
						<div class="d-flex justify-content-center mt-2">
							<!-- <button type="button" data-dismiss="modal" (click)="closeModal()"
								class="btn btnStyle">Close</button> -->
							<button class="btn saveButtonModal" (click)="submit(inputmsg.value, sendMessageField)"
								data-dismiss="modal">Send</button>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="row" style="margin-top: 20px;">
				<div class="col-md-12 d-flex justify-content-end align-items-center">
					<h5 class="mr-2" style="margin-top: 14px;">
						Connect with Admin</h5> &nbsp;
					<a href="tel:{{'+17082056607'}}"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 122.88 122.88" height="38" width="48">
							<defs>
								<style>
									.phoneCls-1 {
										fill: #33a867;
										fill-rule: evenodd;
									}
								</style>
							</defs>
							<path class="phoneCls-1"
								d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0ZM48.1,56.19A49.35,49.35,0,0,0,55.39,66.4a41.25,41.25,0,0,0,11.55,8.51,1.14,1.14,0,0,0,1,0,4.48,4.48,0,0,0,1.39-1A16.91,16.91,0,0,0,70.6,72.4c1.85-2.44,4.15-5.47,7.39-4l.2.11L89,74.77l.11.07a4.84,4.84,0,0,1,2,4.2,11.43,11.43,0,0,1-1.58,5.37,11.06,11.06,0,0,1-5.2,4.6A23.63,23.63,0,0,1,78,90.75a21.69,21.69,0,0,1-9.8-.82,43.06,43.06,0,0,1-9.85-4.76L58.05,85c-1.6-1-3.33-2.07-5-3.33a62.69,62.69,0,0,1-16.65-18.9c-3.46-6.26-5.34-13-4.32-19.44a14,14,0,0,1,4.72-8.85A12.68,12.68,0,0,1,46.18,32a1.35,1.35,0,0,1,1.09.7L54.21,44.4a3.68,3.68,0,0,1,.59,3.92,7.71,7.71,0,0,1-2.66,3c-.37.32-.82.64-1.29,1-1.55,1.12-3.32,2.42-2.71,4l0-.05Z" />
						</svg></a> &nbsp;
					<a href="mailto:info@1800limo.com" target="_blank"><svg xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 48 48" width="48px" height="48px">
							<linearGradient id="NvW_RwTAona1rlOp72mkqa" x1="24" x2="24" y1="42" y2="6.207"
								gradientUnits="userSpaceOnUse">
								<stop offset="0" stop-color="#1ac8fc" />
								<stop offset=".258" stop-color="#1bb6fa" />
								<stop offset=".775" stop-color="#1c86f5" />
								<stop offset="1" stop-color="#1d70f2" />
							</linearGradient>
							<path fill="url(#NvW_RwTAona1rlOp72mkqa)"
								d="M34,42H14c-4.411,0-8-3.589-8-8V14c0-4.411,3.589-8,8-8h20c4.411,0,8,3.589,8,8v20 C42,38.411,38.411,42,34,42z" />
							<path
								d="M34,16c0.178,0,0.347,0.031,0.512,0.074l-7.824,7.825C25.946,24.64,24.973,25.01,24,25.01 s-1.946-0.37-2.688-1.111l-7.824-7.825C13.653,16.031,13.822,16,14,16H34 M35.926,17.488C35.969,17.653,36,17.822,36,18v12 c0,0.178-0.031,0.347-0.074,0.512L29.414,24L35.926,17.488 M12.074,17.488L18.586,24l-6.511,6.511C12.031,30.347,12,30.178,12,30 V18C12,17.822,12.031,17.653,12.074,17.488 M27.99,25.404l6.522,6.522C34.347,31.969,34.178,32,34,32H14 c-0.178,0-0.347-0.031-0.512-0.074l6.522-6.522c1.117,1.063,2.55,1.605,3.99,1.605S26.873,26.467,27.99,25.404 M34,15H14 c-0.244,0-0.495,0.035-0.768,0.108c-0.346,0.092-0.616,0.361-0.709,0.707c-0.092,0.341,0.003,0.704,0.248,0.956 c-0.189-0.183-0.44-0.283-0.697-0.283c-0.087,0-0.174,0.011-0.26,0.034c-0.345,0.093-0.615,0.363-0.707,0.709 C11.035,17.505,11,17.756,11,18v12c0,0.244,0.035,0.495,0.108,0.768c0.092,0.346,0.361,0.616,0.707,0.709 c0.086,0.023,0.173,0.034,0.26,0.034c0.257,0,0.508-0.099,0.696-0.282c-0.245,0.253-0.34,0.616-0.248,0.956 c0.093,0.345,0.363,0.615,0.709,0.707C13.505,32.965,13.756,33,14,33h20c0.244,0,0.495-0.035,0.768-0.108 c0.346-0.092,0.616-0.361,0.709-0.707c0.092-0.341-0.003-0.704-0.248-0.956c0.189,0.183,0.44,0.282,0.696,0.282 c0.087,0,0.174-0.011,0.26-0.034c0.345-0.093,0.615-0.363,0.707-0.709C36.965,30.495,37,30.244,37,30V18 c0-0.244-0.035-0.495-0.108-0.768c-0.092-0.346-0.361-0.616-0.707-0.709c-0.086-0.023-0.173-0.034-0.26-0.034 c-0.257,0-0.508,0.099-0.697,0.282c0.245-0.253,0.34-0.616,0.248-0.956c-0.093-0.345-0.363-0.615-0.709-0.707 C34.495,15.035,34.244,15,34,15L34,15z"
								opacity=".05" />
							<path
								d="M34,15.5H14c-0.203,0-0.406,0.029-0.64,0.091c-0.173,0.046-0.308,0.181-0.354,0.353 c-0.046,0.173,0.003,0.357,0.129,0.483l7.824,7.825C21.77,25.063,22.85,25.51,24,25.51s2.23-0.447,3.041-1.257l7.824-7.825 c0.126-0.126,0.176-0.311,0.129-0.483c-0.046-0.173-0.182-0.307-0.354-0.353C34.406,15.529,34.203,15.5,34,15.5L34,15.5z M35.926,16.988c-0.131,0-0.259,0.051-0.354,0.146l-6.511,6.512c-0.195,0.195-0.195,0.512,0,0.707l6.511,6.511 c0.095,0.095,0.223,0.146,0.354,0.146c0.043,0,0.087-0.006,0.13-0.017c0.173-0.046,0.307-0.182,0.353-0.354 C36.471,30.406,36.5,30.203,36.5,30V18c0-0.203-0.029-0.406-0.091-0.64c-0.046-0.173-0.181-0.308-0.353-0.354 C36.013,16.994,35.969,16.988,35.926,16.988L35.926,16.988z M12.074,16.988c-0.043,0-0.087,0.006-0.13,0.017 c-0.173,0.046-0.307,0.182-0.353,0.354C11.529,17.594,11.5,17.797,11.5,18v12c0,0.203,0.029,0.406,0.091,0.64 c0.046,0.173,0.181,0.308,0.353,0.354c0.043,0.012,0.087,0.017,0.13,0.017c0.131,0,0.259-0.051,0.354-0.146l6.511-6.511 c0.195-0.195,0.195-0.512,0-0.707l-6.511-6.512C12.333,17.04,12.205,16.988,12.074,16.988L12.074,16.988z M27.99,24.904 c-0.124,0-0.248,0.046-0.345,0.138c-0.995,0.946-2.289,1.467-3.645,1.467s-2.651-0.521-3.645-1.467 c-0.097-0.092-0.221-0.138-0.345-0.138c-0.128,0-0.256,0.049-0.354,0.146l-6.522,6.522c-0.126,0.126-0.176,0.311-0.129,0.483 s0.182,0.307,0.354,0.353C13.594,32.471,13.797,32.5,14,32.5h20c0.203,0,0.406-0.029,0.64-0.091 c0.173-0.046,0.308-0.181,0.354-0.353s-0.003-0.357-0.129-0.483l-6.522-6.522C28.246,24.953,28.118,24.904,27.99,24.904 L27.99,24.904z"
								opacity=".07" />
							<path fill="#f9f9f9"
								d="M35.926,17.488L29.414,24l6.511,6.511C35.969,30.347,36,30.178,36,30V18 C36,17.822,35.969,17.653,35.926,17.488z M26.688,23.899l7.824-7.825C34.347,16.031,34.178,16,34,16H14 c-0.178,0-0.347,0.031-0.512,0.074l7.824,7.825C22.795,25.38,25.205,25.38,26.688,23.899z M12.074,17.488 C12.031,17.653,12,17.822,12,18v12c0,0.178,0.031,0.347,0.074,0.512L18.586,24L12.074,17.488z M24,27.009 c-1.44,0-2.873-0.542-3.99-1.605l-6.522,6.522C13.653,31.969,13.822,32,14,32h20c0.178,0,0.347-0.031,0.512-0.074l-6.522-6.522 C26.873,26.467,25.44,27.009,24,27.009z" />
						</svg>
					</a>

				</div>
			</div> -->
			<!-- <div class="modal-footer pt-0" [style.display]="show ? 'none' : ''">
				<button type="button" data-dismiss="modal" (click)="closeModal()" class="btn btnStyle">Close</button>
			</div> -->
		</div>
	</div>
</div>

<!-- Notification Modal -->
<div class="modal fade" id="notificationModal" tabindex="-1" role="dialog" aria-labelledby="notificationModal"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close closeButton" data-dismiss="notificationModal" aria-label="Close"
				id="closeModal">
				<span aria-hidden="true">&times;</span>
			</button>
			<div class="modal-body">
				<h5 class="text-center">{{notification_msg}}</h5>
			</div>
			<div class="modal-footer py-0">
				<button type="button" class="custom-btn button2" data-dismiss="notificationModal"
					id="closeModal1">OK</button>
			</div>
		</div>
	</div>
</div>






<!-- booking periview -->
<div class="modal fade" id="previewBookingOnID" tabindex="-1" aria-labelledby="previewBookingLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content" style="background: #f5f4fe;">
			<div class="modal-header d-flex justify-content-between py-0">
				<h3 class="modal-title">
					Preview Booking #{{bookingPreview?.reservation_id}}
				</h3>
				<!-- <button type="button" class="close x-circle-btn" data-dismiss="modal" aria-label="Close" >
					<span aria-hidden="true" class="bi bi-x-circle"></span>
				</button> -->
				<button class="custom-filled-button orange-button" data-toggle="modal" data-target="#previewCopyData"
					(click)="closePreview()">Show Content</button>
			</div>
			<div class="modal-body" style="font-family: 'PT Serif', serif;">
				<div class="container text-lg" *ngIf="bookingPreview">

					<h4 class="font-weight-bolder m-0 mt-1">
						Rates / Taxes
					</h4>
					<div class="row" *ngIf="bookingPreview?.grand_total">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Grand Total
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{bookingPreview?.currency_symbol}} {{ bookingPreview?.grand_total }}
							</span>
						</div>
					</div>
					<!-- Booking Type -->
					<h4 class="font-weight-bolder m-0">
						Booking Type
					</h4>
					<!-- Booking Information -->
					<div class="row">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Booking Information
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{ textFormatter(bookingPreview?.service_type) | titlecase }}
								/
								{{ textFormatter(bookingPreview?.transfer_type) | titlecase }}
								<span class="font-weight-bolder" *ngIf="bookingPreview?.service_type == 'round_trip'">
									/ {{ textFormatter(bookingPreview?.return_transfer_type) |
									titlecase
									}}
								</span>
							</span>
						</div>
					</div>
					<div class="row preview-dropoff-color" *ngIf="bookingPreview?.cancellation_hours">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Cancellation Period
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{ bookingPreview?.cancellation_hours }} hours
							</span>
						</div>
					</div>
					<hr>
					<!-- Booking Information - Ends -->





					<!-- Grand Total -->



					<!-- Travel Information -->
					<h4 class="font-weight-bolder m-0 mt-3">
						Travel Information
					</h4>

					<!-- Number of Hours -->
					<div class="mx-2" *ngIf="bookingPreview?.service_type == 'Charter Tour'">
						<div class="row">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Booking Hours
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ bookingPreview?.number_of_hours }}
								</span>
							</div>
						</div>
					</div>

					<!-- ################ Pickup ################### -->
					<h5 class="m-0 my-2 font-weight-bolder text-2xl">
						Pickup Details
					</h5>
					<!-- Pickup > Date -->
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Date
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ dateFormat2(bookingPreview?.pickup_date) }}
									|
									{{ dateFormat(bookingPreview?.pickup_date) }}
								</span>
							</div>
						</div>
					</div>

					<!-- Pickup > Time -->
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Time
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ timeFormat2(bookingPreview?.pickup_time) }}
									|
									{{ timeFormat(bookingPreview?.pickup_time) }}
								</span>
							</div>
						</div>

					</div>

					<!-- Pickup > City Details -->
					<div class="mx-2"
						*ngIf="bookingPreview?.transfer_type.includes('city_') || bookingPreview?.transfer_type.includes('cruise_')">
						<hr>
						<div class="row m-0">
							<div class="col-md-4">
								<span style="font-weight: 550 !important;">
									{{bookingPreview?.extra_stops ? 'First Pickup' : 'Address'}}
								</span>
							</div>
							<div class="col-md-8">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.pickup) }}
								</span>
								<span (click)="showLocationPointOnMapByAddress(bookingPreview?.pickup)"><i
										class="bi bi-geo-alt-fill pin-icon-preview"></i></span>
							</div>
						</div>

					</div>


					<!-- Pickup > Airline Details -->
					<div class="mx-2" *ngIf="bookingPreview?.transfer_type.includes('airport_')">
						<!-- Airport -->
						<hr>
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Airport
								</span>
							</div>
							<div class="col-md-8 value preview-airport-color">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.pickup_airport_name) }}
								</span>
								<span (click)="showLocationPointOnMapByAddress(bookingPreview?.pickup_airport_name)"><i
										class="bi bi-geo-alt-fill pin-icon-preview"></i></span>
							</div>
						</div>
						<!-- Airport -->
						<!-- Airline -->
						<hr>
						<div class="row m-0 ">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Airline
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild"
									style="cursor: pointer;text-decoration: underline;color: #ff0000 !important;"
									(click)="searchOnGoogle(bookingPreview?.pickup_airline_name + ' ' + bookingPreview?.pickup_flight)">
									{{ textFormatter(bookingPreview?.pickup_airline_name) }}
								</span> &nbsp;<span class="font-weight-bolder preview-output-feild"
									style="cursor: pointer;text-decoration: underline;color: #ff0000 !important;"
									(click)="searchOnGoogle(bookingPreview?.pickup_airline_name + ' ' + bookingPreview?.pickup_flight)">
									#{{ textFormatter(bookingPreview?.pickup_flight) }}
								</span>&nbsp;
								<span
									style="color: blue;text-decoration: underline;font-weight: 600;cursor: pointer;font-size: 18px;"
									(click)="searchOnGoogle(bookingPreview?.pickup_airline_name + ' ' + bookingPreview?.pickup_flight)">(Update
									Arrival time)</span>
							</div>
						</div>
						<!-- Airline -->
						<!-- Flight Number -->
						<!-- <hr>
						<div class="row m-0 preview-pickup-color">
							<div class="col-md-5 key">
								<span>
									Flight Number &nbsp;
								</span>
							</div>
							<div class="col-md-7 value">
								<span class="font-weight-bolder preview-output-feild"
									style="cursor: pointer;text-decoration: underline;"
									(click)="searchOnGoogle(bookingPreview?.pickup_airline_name + ' ' + bookingPreview?.pickup_flight)">
									{{ textFormatter(bookingPreview?.pickup_flight) }}
								</span>
							</div>
						</div> -->
						<!-- Flight Number -->

					</div>

					<!-- Pickup > Cruise Details -->
					<div class="mx-2 preview-pickup-color" *ngIf="bookingPreview?.transfer_type.includes('cruise_')">
						<!-- Cruise Port -->
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Cruise Port
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.cruise_port) | titlecase }}
								</span>
							</div>
						</div>
						<!-- Cruise Port -->
						<!-- Cruise Ship Name -->
						<div class="row m-0 preview-pickup-color">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Cruise Ship Name
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.cruise_name) | titlecase }}
								</span>
							</div>
						</div>
						<!-- Cruise Ship Name -->
						<!-- Ship Arrival time -->
						<div class="row m-0 preview-pickup-color">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Ship Arrival time
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.cruise_time) | titlecase }}
								</span>
							</div>
						</div>
					</div>
					<hr>
					<!-- Extra Stops -->
					<div *ngIf="bookingPreview?.extra_stops && bookingPreview?.extra_stops.length > 0">
						<h5 class="m-0 text-xl" style="color: darkblue;">
							Extra Stops
						</h5>
						<div class="mx-2 preview-extra-stop-color"
							*ngFor="let stop of bookingPreview?.extra_stops; index as stop_index">
							<div class="row m-0 mx-2">
								<div class="col-md-4 key">
									<span style="font-weight: 550 !important;">
										Stop {{ stop_index + 1 }}
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder preview-output-feild">
										{{ stop.address }}
									</span>
									<span (click)="showLocationPointOnMapByAddress(stop.address)"><i
											class="bi bi-geo-alt-fill pin-icon-preview"></i></span>
								</div>
							</div>
							<hr>
						</div>
					</div>

					<hr style="width: 50%; margin: 1rem auto; border: 1.3px solid #aeaeae;"
						*ngIf="bookingPreview?.extra_stops && bookingPreview?.extra_stops.length == 0" />

					<!-- ############# Dropoff ################# -->
					<h5 class="px-2 m-0 my-3 font-weight-bolder text-2xl">
						Drop Off Details
					</h5>
					<!-- Dropoff > City Details -->
					<div class="mx-2"
						*ngIf="bookingPreview?.transfer_type.includes('_city') || bookingPreview?.transfer_type.includes('_cruise')">
						<div class="row">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Address
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.dropoff) }}
								</span>
								<span (click)="showLocationPointOnMapByAddress(bookingPreview?.dropoff)"><i
										class="bi bi-geo-alt-fill pin-icon-preview"></i></span>
							</div>
						</div>
					</div>

					<!-- Dropoff > Airline Details -->
					<div class="mx-2" *ngIf="bookingPreview?.transfer_type.includes('_airport')">
						<!-- Airport -->
						<div class="row">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Airport
								</span>
							</div>
							<div class="col-md-8 value preview-airport-color">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.dropoff_airport_name ) }}
								</span>
								<span (click)="showLocationPointOnMapByAddress(bookingPreview?.dropoff_airport_name)"><i
										class="bi bi-geo-alt-fill pin-icon-preview"></i></span>
							</div>
						</div>
						<!-- Airport -->
						<!-- Airline -->
						<hr>
						<div class="row">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Airline
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild"
									style="cursor: pointer;text-decoration: underline;color: #ff0000 !important;"
									(click)="searchOnGoogle(bookingPreview?.dropoff_airline_name + ' ' + bookingPreview?.dropoff_flight)">
									{{ textFormatter(bookingPreview?.dropoff_airline_name) }}
								</span>&nbsp;
								<span class="font-weight-bolder preview-output-feild"
									style="cursor: pointer;text-decoration: underline;color: #ff0000 !important;"
									(click)="searchOnGoogle(bookingPreview?.dropoff_airline_name + ' ' + bookingPreview?.dropoff_flight)">
									#{{ textFormatter(bookingPreview?.dropoff_flight) }}
								</span>&nbsp;
								<span
									style="color: blue;text-decoration: underline;font-weight: 600;cursor: pointer;font-size: 18px;"
									(click)="searchOnGoogle(bookingPreview?.dropoff_airline_name + ' ' + bookingPreview?.dropoff_flight)">(Update
									Arrival time)</span>
							</div>
						</div>
						<!-- Airline -->
						<!-- Flight Number -->
						<!-- <hr>
						<div class="row preview-dropoff-color">
							<div class="col-md-5 key">
								<span>
									Flight Number &nbsp;<span
										style="color: blue;text-decoration: underline;font-weight: 600;cursor: pointer;font-size: 18px;"
										(click)="searchOnGoogle(bookingPreview?.dropoff_airline_name + ' ' + bookingPreview?.dropoff_flight)">(Update
										Arrival time)</span>
								</span>
							</div>
							<div class="col-md-7 value">
								<span class="font-weight-bolder preview-output-feild"
									style="cursor: pointer;text-decoration: underline;color: #007500 !important;"
									(click)="searchOnGoogle(bookingPreview?.dropoff_airline_name + ' ' + bookingPreview?.dropoff_flight)">
									{{ textFormatter(bookingPreview?.dropoff_flight) }}
								</span>
							</div>
						</div> -->
						<!-- Flight Number -->
					</div>

					<!-- Dropoff > Cruise Details -->
					<div class="mx-2 preview-dropoff-color" *ngIf="bookingPreview?.transfer_type.includes('_cruise')">
						<!-- Cruise Port -->
						<div class="row">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Cruise Port
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.cruise_port) | titlecase }}
								</span>
							</div>
						</div>
						<!-- Cruise Port -->

						<!-- Cruise Ship Name -->
						<div class="row preview-dropoff-color">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Cruise Ship Name
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.cruise_name) | titlecase }}
								</span>
							</div>
						</div>
						<!-- Cruise Ship Name -->

						<!-- Ship Arrival time -->
						<div class="row preview-dropoff-color">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Ship Arrival time
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.cruise_time) }}
								</span>
							</div>
						</div>
						<!-- Ship Arrival time -->
					</div>
					<!-- Dropoff > Cruise Details - Ends -->

					<!-- 2ND p -->
					<!-- Distance Details -->
					<div class="mx-2">
						<hr>
						<div class="row m-0">
							<div class="col-md-4">
								<span style="font-weight: 550 !important;">
									Estimated Total Distance
								</span>
							</div>
							<div class="col-md-8 ">
								<span class="font-weight-bolder preview-output-feild">
									{{ mToMi(bookingPreview?.distance) }} Miles / {{ mToKm(bookingPreview?.distance)
									}} Km
								</span>
							</div>
						</div>
					</div>
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4">
								<span style="font-weight: 550 !important;">
									Estimated time
								</span>
							</div>
							<div class="col-md-8">
								<span class="font-weight-bolder preview-output-feild">
									{{convertToMinutes(bookingPreview?.duration)}}
								</span>
							</div>
						</div>

					</div>
					<hr>
					<br>
					<div class="google_map">
						<div id="map" class="map"></div>
					</div>
					<br>
					<!-- Distance Details -->
					<hr>
					<!-- Passenger Information -->
					<h4 class="font-weight-bolder m-0 mt-3">
						Passenger Information
					</h4>

					<!-- Passenger > Name -->
					<div class="mx-2 preview-pickup-color">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Name
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.passenger_name) }}
								</span>
							</div>
						</div>
					</div>
					<!-- Passenger > Phone -->
					<div class="mx-2 preview-pickup-color">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Phone
								</span>
							</div>
							<div class="col-md-8 value">
								<a class="font-weight-bolder preview-output-feild cursor-pointer"
									style="color: #1e7500;"
									href="tel:{{bookingPreview?.passenger_cell_isd}}{{bookingPreview?.passenger_cell}}">
									({{ bookingPreview?.passenger_cell_isd }})
									{{ textFormatter(bookingPreview?.passenger_cell) }}
								</a>
							</div>
						</div>
					</div>
					<!-- Passenger > Email -->
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Email
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.passenger_email) }}
								</span>
							</div>
						</div>
					</div>


					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									No. of passenger
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ bookingPreview?.total_passengers }}
								</span>
							</div>
						</div>
					</div>
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Luggage count
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ bookingPreview?.luggage_count }}
								</span>
							</div>
						</div>
					</div>

					<hr>
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Special Instruction
								</span>
							</div>
							<div class="col-md-8 value box-special-instruction">
								<span class="font-weight-bolder preview-output-feild" style="white-space: pre-wrap;"
									[innerHTML]="highlightNumbers(bookingPreview?.booking_instructions || ' ')">
									<!-- {{ highlightNumbers(bookingPreview?.booking_instructions) }} -->
								</span>
							</div>
						</div>
					</div>
					<hr>
					<div class="mx-2" *ngIf="bookingPreview?.meet_greet_choice_name">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Meet and Greet
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.meet_greet_choice_name)| titlecase }}
								</span>
							</div>
						</div>
					</div>
					<div class="mx-2">
						<div class="row m-0" *ngIf="bookingPreview?.return_meet_greet_choice_name">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Return Meet and Greet
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.return_meet_greet_choice_name)| titlecase }}
								</span>
							</div>
						</div>
					</div>
					<hr>

					<!-- Grand Total -->
					<h4 class="font-weight-bolder m-0">
						Vehicle Preferences
					</h4>
					<div class="row p-2">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Vehicle Type
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{ bookingPreview?.vehicle_type_name}}
							</span>
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Vehicle Make
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{ bookingPreview?.vehicle_make}}
							</span>
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Vehicle Model
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{ bookingPreview?.vehicle_model}}
							</span>
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Vehicle Year
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{ bookingPreview?.vehicle_year}}
							</span>
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Vehicle Color
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{ bookingPreview?.vehicle_color}}
							</span>
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Licence plate
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{ bookingPreview?.vehicle_license_plate}}
							</span>
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								No. of seats
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{ bookingPreview?.vehicle_seats}}
							</span>
						</div>
					</div>
					<hr>
					<!-- affiliate details -->
					<ng-container *ngIf="bookingPreview?.affiliate_type == 'affiliate' then affiliateUser">
					</ng-container>

					<!-- Affiliate -->
					<ng-template #affiliateUser>
						<h4 class="font-weight-bolder m-0 mt-3">
							Affiliate Information
						</h4>

						<!-- Affiliate > Name -->
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span style="font-weight: 550 !important;">
										Name
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder preview-output-feild"
										*ngIf="bookingPreview?.MiddleName == null;else affiliateMiddleName">
										{{ textFormatter(bookingPreview?.affiliate_name) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Affiliate > Email -->
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span style="font-weight: 550 !important;">
										Email
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder preview-output-feild">
										{{ textFormatter(bookingPreview?.affiliate_email) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Affiliate > Phone -->
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span style="font-weight: 550 !important;">
										Phone
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder preview-output-feild">
										({{ bookingPreview?.affiliate_phone_isd }}){{
										textFormatter(bookingPreview?.affiliate_phone) }}
									</span>
								</div>
							</div>
						</div>

					</ng-template>

					<!-- loose affiliate details -->
					<ng-container *ngIf="bookingPreview?.affiliate_type == 'loose_affiliate' then looseAffiliate">
					</ng-container>

					<ng-template #looseAffiliate>
						<h4 class="font-weight-bolder m-0 mt-3">
							Loose Affiliate Information
						</h4>

						<!-- Loose Affiliate > Name -->
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span style="font-weight: 550 !important;">
										Name
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder preview-output-feild">
										{{ textFormatter(bookingPreview?.lose_affiliate_name) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Loose Affiliate > Email -->
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span style="font-weight: 550 !important;">
										Email
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder preview-output-feild">
										{{ textFormatter(bookingPreview?.lose_affiliate_email) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Loose Affiliate > Phone -->
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span style="font-weight: 550 !important;">
										Phone
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder preview-output-feild">
										({{ bookingPreview?.lose_affiliate_phone_isd }}){{
										textFormatter(bookingPreview?.lose_affiliate_phone) }}
									</span>
								</div>
							</div>
						</div>

					</ng-template>
					<hr>
					<!-- Driver Information -->
					<h4 class="font-weight-bolder m-0 mt-3">
						Driver Information
					</h4>
					<!-- Driver Name -->
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Name
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{bookingPreview?.driver_name ? (textFormatter(bookingPreview?.driver_name) |
									titlecase) : '1800Limo Chauffeurs' }}
								</span>
							</div>
						</div>
					</div>

					<!-- Driver Email -->
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Email
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ textFormatter(bookingPreview?.driver_email) }}
								</span>
							</div>
						</div>
					</div>

					<!-- Driver Cell -->
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span style="font-weight: 550 !important;">
									Phone
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-output-feild">
									{{ bookingPreview?.driver_cell_isd ? ( bookingPreview?.driver_cell_isd ) : '(+1) '
									}}{{
									bookingPreview?.driver_cell ? bookingPreview?.driver_cell : '8005466266' }}
								</span>
							</div>
						</div>
					</div>
					<hr>

					<div class="row" *ngIf="bookingPreview?.total_amount_refunded>0">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								Refund amount
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{bookingPreview?.currency_symbol}}
								{{-(bookingPreview?.total_amount_refunded).toFixed(2) }}
							</span>
						</div>
					</div>
					<div class="row" *ngIf="bookingPreview?.total_amount_refunded>0">
						<div class="col-md-4 key">
							<span style="font-weight: 550 !important;">
								New total
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder preview-output-feild">
								{{bookingPreview?.currency_symbol}} {{(bookingPreview?.grand_total-
								bookingPreview?.total_amount_refunded).toFixed(2) }}
							</span>
						</div>
					</div>







					<!-- Rate Distribution Information -->
					<!-- <div class="distribution_rate"
						*ngIf="bookingPreview?.payment_status=='unpaid' && bookingPreview?.share_array?.length == 0">
						<h4 class="font-weight-bolder m-0 my-3">
							Rates Distribution {{bookingPreview?.loose_affiliate}}
						</h4>

						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span class="">
										Base Rate
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{rates_preview?.ride_base_rate.toFixed(2)}}
									</span>
									(Fare Base Rate)
								</div>
							</div>
						</div>

						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span class="">
										Admin Share
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{rates_preview?.admin_share.toFixed(2) }}
									</span>
									({{adminSharePercent}} of Base Rate)
								</div>
							</div>
						</div>

						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span class="">
										Other Taxes
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{rates_preview?.other_taxes.toFixed(2)}}
									</span>
									(Total of all taxes and other charges)
								</div>
							</div>
						</div>
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span class="">
										Total Cost
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{rates_preview?.total_price.toFixed(2)}}
									</span>
									(Actual Cost to Customer)
								</div>
							</div>
						</div>
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span class="">
										Stripe fees
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{rates_preview?.stripe_fees.toFixed(2)}}
									</span>
									(Platform fee 2.9% + 30 cents - <b>Paid from Admin share</b>)
								</div>
							</div>
						</div>
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span class="">
										Actual Amount Recieved
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{(rates_preview?.total_price -
										rates_preview?.stripe_fees).toFixed(2)}}
									</span>
									(Excluding platform fee)
								</div>
							</div>
						</div>

						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span class="">
										Affliate Payout
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{rates_preview?.affiliate_share.toFixed(2)}}
									</span>
									( Total Price - (Ride Base Rate + Other Taxes) )
								</div>
							</div>
						</div>
					</div> -->
					<!-- *ngIf="bookingPreview?.payment_status=='unpaid' && bookingPreview?.share_array?.length != 0" -->
					<div class="distribution_rate" *ngIf="bookingPreview?.affiliate_type != 'unassigned'">
						<h4 class="font-weight-bolder m-0 my-3">
							Rates Distribution
						</h4>

						<div class="mx-2" style="font-size: 18px;">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span style="font-weight: 550 !important;">
										Base Rate
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{formatBaseRate(bookingPreview?.share_array?.baseRate)}}
									</span>
									(Fare Base Rate)
								</div>
							</div>
						</div>
						<div class="mx-2" style="font-size: 18px;" *ngIf="bookingPreview?.share_array?.adminShare">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span class="font-weight-bolder" style="color: #1e7500">
										Admin Share
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color" style="color: #1e7500">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{formatBaseRate(bookingPreview?.share_array?.adminShare)
										}}
									</span>
									({{adminSharePercent}}% of Base Rate)
								</div>
							</div>
						</div>
						<div class="mx-2" style="font-size: 18px;"
							*ngIf="bookingPreview?.share_array?.travelAgentShare">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span style="font-weight: 550 !important;">
										Travel Agent Share
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{formatBaseRate(bookingPreview?.share_array?.travelAgentShare)
										}}
									</span>
									(10% of Base Rate)
								</div>
							</div>
						</div>

						<div class="mx-2" style="font-size: 18px;">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span style="font-weight: 550 !important;">
										Stripe fees
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{formatBaseRate(bookingPreview?.share_array?.stripeFee)}}
									</span>
									(Platform fee 5% + 30 cents - <b>Paid from Admin share</b>)
								</div>
							</div>
						</div>

						<div class="mx-2" style="font-size: 18px;">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span style="font-weight: 550 !important;">
										Affliate Payout
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{formatBaseRate(bookingPreview?.share_array?.affiliateShare)}}
									</span>
									( Total Price - (Ride Base Rate + Other Taxes) )
								</div>
							</div>
						</div>
						<div class="mx-2" style="font-size: 18px;" *ngIf="bookingPreview?.share_array?.farmoutShare">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span style="font-weight: 550 !important;">
										Affliate Farm out share
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{formatBaseRate(bookingPreview?.share_array?.farmoutShare)}}
									</span>
									( 10 % of Base Rate)
								</div>
							</div>
						</div>
						<div class="mx-2" style="font-size: 18px;">
							<div class="row m-0">
								<div class="col-md-4 key d-flex align-items-center">
									<span style="font-weight: 550 !important;">
										Total Cost
									</span>
								</div>
								<div class="col-md-8 value preview-airport-color">
									<span class="font-weight-bolder preview-output-feild">
										{{bookingPreview?.currency_symbol}}{{bookingPreview?.share_array?.grandTotal ?
										formatBaseRate(bookingPreview?.share_array?.grandTotal) :
										formatBaseRate(bookingPreview?.share_array?.returnGrandTotal)}}
									</span>
									(Actual Cost to Customer)
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>



			<div class="modal-footer justify-content-end">
				<button type="button" class="close x-circle-btn" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" class="bi bi-x-circle"></span>
				</button>

			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="previewCopyData" tabindex="-1" aria-labelledby="previewCopyDataLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-body">
				<pre
					style="white-space: pre-wrap;font-family: 'PT Serif', serif;font-size: 18px;">{{ previewCopyData }}</pre>
			</div>
			<div class="modal-footer justify-content-end">
				<button type="button" class="close x-circle-btn" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" class="bi bi-x-circle"></span>
				</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="cancelModal" tabindex="-1" aria-labelledby="cancelModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-body">
				<h5 class="text-center">Are you sure you want to cancel your subscription?</h5>
				<span class="d-block text-center">Cancellation will be effective after {{subs_end_date}}</span>
			</div>
			<div class="modal-footer py-0">
				<button type="button" class="custom-btn button2" data-dismiss="modal" aria-label="Close">No</button>
				<button type="button" class="custom-btn button2" (click)="cancelSubscription()">
					Yes, Cancel now
				</button>
			</div>
		</div>
	</div>
</div>