<div class="innerpage_wrapper">
	<!--------Aboutus video----------------->
	<div class="innerpage_topbanner">
		<h2>
			About us
			<hr>
		</h2>
	</div>
	<!--------About us ----------------->
	<section class="abouthistory_wraper">
		<div class="container">
			<!-- <div class="innpage_video">
                <iframe width="100%" height="500px" src="https://www.youtube.com/embed/cShk_hqvoa0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> -->
			<div class="innpage_video">
				<!-- <iframe width="100%" height="500px" src="https://www.youtube.com/embed/cShk_hqvoa0" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
				<!-- <a href="assets/images/registerhere.jpg">
                    <div class="thumbnail">
                        <iframe width="100%" height="500px" src="https://www.youtube.com/embed/cShk_hqvoa0"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </a> -->
				<video controls poster="assets/images/registerhere.jpg">
					<source src="assets/images/Investor Video-1800LIMO.COM.mp4" type="video/mp4">
				</video>
			</div>
		</div>
	</section>
	<section class="aboutnw_slider">
		<div class="container">
			<!-- for desktop -->
			<div class="loopd owl-carousel owl-theme">
				<div class="item" data-hash="first">
					<div class="custom-radio">
						<input class="radio-button" type="radio" id="1994" value="1994" name="carouselType" checked
							(click)="carouselSwitch('1994')" tabIndex="1" />
						<div class="aboutnw_slideriner text-center" name="carouselType" data-target="#myCarousel">
							<h3>1994</h3>
							<p>The First Wireless Communication Vision For The Limo Industry</p>
						</div>
					</div>
				</div>
				<div class="item" data-hash="second">
					<div class="custom-radio">
						<input class="radio-button" type="radio" id="1995" value="1995" name="carouselType"
							(click)="carouselSwitch('1995')" tabIndex="1" />
						<div class="aboutnw_slideriner text-center" name="carouselType" data-target="#myCarousel">
							<h3>1995</h3>
							<p>Proposed Partnership with In-Flight Phone Corp and Boston Coach</p>
						</div>
					</div>
				</div>
				<div class="item" data-hash="third">
					<div class="custom-radio">
						<input class="radio-button" type="radio" id="2001" value="2001" name="carouselType"
							(click)="carouselSwitch('2001')" tabIndex="1" />
						<div class="aboutnw_slideriner text-center" name="carouselType" data-target="#myCarousel">
							<h3>2001</h3>
							<p>Chicago Sun-Times Article 1-800-LIMO.COM Comes To Market</p>
						</div>
					</div>
				</div>
				<div class="item" data-hash="four">
					<div class="custom-radio">
						<input class="radio-button" type="radio" id="2007" value="2007" name="carouselType"
							(click)="carouselSwitch('2007')" tabIndex="1" />
						<div class="aboutnw_slideriner text-center" name="carouselType" data-target="#myCarousel">
							<h3>2007</h3>
							<p>2007 – iPhone Comes To Market</p>
						</div>
					</div>
				</div>
				<div class="item" data-hash="five">
					<div class="custom-radio">
						<input class="radio-button" type="radio" id="2008" value="2008" name="carouselType"
							(click)="carouselSwitch('2008')" tabIndex="1" />
						<div class="aboutnw_slideriner text-center" name="carouselType" data-target="#myCarousel">
							<h3>2008</h3>
							<p>March 2009 – Uber Technologies Starts funded by Google Ventures and others.</p>
						</div>
					</div>
				</div>
				<div class="item" data-hash="six">
					<div class="custom-radio">
						<input class="radio-button" type="radio" id="2010" value="2010" name="carouselType"
							(click)="carouselSwitch('2010')" tabIndex="1" />
						<div class="aboutnw_slideriner text-center" name="carouselType" data-target="#myCarousel">
							<h3>2010</h3>
							<p>June 2012 – Lyft, Inc Starts and funded by numerous Silicon Venture firms and others.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="d-sm-flex d-none justify-content-center">
				<a class="clr-Fil btn-primary active mt-4 mx-2 url" href="about-us#first">First</a>
				<a class="clr-Fil btn-primary mt-4 mx-2 url" href="about-us#six">Last</a>
			</div>

			<!-- carousal for mobile -->
			<div class="d-sm-none d-flex flex-sm-nowrap flex-wrap justify-content-center">
				<a class="clr-Fil btn-primary active mt-4 mx-2 url" (click)="carouselSwitch('1994')"
					href="about-us#first">1994</a>
				<a class="clr-Fil btn-primary mt-4 mx-2 url" (click)="carouselSwitch('1995')"
					href="about-us#second">1995</a>
				<a class="clr-Fil btn-primary mt-4 mx-2 url" (click)="carouselSwitch('2001')"
					href="about-us#third">2001</a>
				<a class="clr-Fil btn-primary mt-4 mx-2 url" (click)="carouselSwitch('2007')"
					href="about-us#four">2007</a>
				<a class="clr-Fil btn-primary mt-4 mx-2 url" (click)="carouselSwitch('2008')"
					href="about-us#five">2008</a>
				<a class="clr-Fil btn-primary mt-4 mx-2 url" (click)="carouselSwitch('2010')"
					href="about-us#six">2010</a>
			</div>

			<!----slider content---->
			<div class="aboutnw_sliderdesc carousel" id="myCarousel">
				<h3>{{carouselLabel}}</h3>
				<h4>{{carouselHeading}}</h4>
				<div *ngIf="selectedCarousel === '2001'; else ShowOtherImage">
					<img src="assets/images/about-us-img.jpg">
				</div>
				<h4 class="mt-4">{{carouselHeading2}}</h4>
				<div *ngIf="selectedCarousel === '2001'; else ShowOtherImage">
					<img src="assets/images/about-us-img-2001-2.jpg">
				</div>
				<ng-template #ShowOtherImage>
					<div *ngIf="selectedCarousel === '1995'; else showInstructions">
						<div class="row img_1995">
							<div class="col-md-4"> <img src="assets/images/skylimo.png"></div>
							<div class="col-md-4"><img src="assets/images/people.png"></div>
							<div class="col-md-4"><img src="assets/images/black.png"></div>
						</div>
					</div>
					<ng-template #showInstructions>
						<div *ngIf="selectedCarousel === '2007'; else showParagraph">
							<img src="assets/images/about-2007.png">
						</div>
						<ng-template #showParagraph>
							<p [innerHTML]="carouselInstructions"></p>
						</ng-template>
					</ng-template>
				</ng-template>
			</div>
		</div>
	</section>
</div>
<!----OUR HISTORY------------->
<section class="aboutnw_history">
	<div class="container">
		<h2>Our History</h2>
		<p [innerHTML]="aboutUsSecionsData[2]?.content"></p>
		<div class="row mt-3">
			<div class="col-lg-6">
				<img src="assets/images/abtnwhistory.jpg" alt="abtnwhistory">
			</div>
			<div class="col-lg-6">
				<div class="aboutnw_historyiner">
					<h3><span>1-800-LIMO.COM </span> Who we are</h3>
					<p>We are the value proposition in safe and reliable ground transportation around locally and the
						world. Our
						web-based digital sales platform matches quality-minded travelers with friendly and professional
						drivers.
						Travel in clean, comfortable
						and well-maintained vehicles at the most competitive available rate.</p>
				</div>
				<div class="aboutnw_historyiner">
					<h3>What we do Best</h3>
					<p>We only want to do "One Thing" and do it better than anyone else. We want to match you with
						discriminating
						clients with professional drivers and chauffures around the world.</p>
				</div>
				<div class="aboutnw_historyiner">
					<h3>Budget to value</h3>
					<p>Our Mission 1-800-LIMO.COM is a web-based digital sales plafrom that matches value and quality
						minded
						luxury travelers with friendly and attentive drivers with safe, reliable, on-time ground
						transportation
						anywhere around the world;
						in clean, comfortable, and well maintained non-autonomous vehicles, at the lowest available
						rates.</p>
				</div>
			</div>
		</div>
		<h2 class="mt-3 mb-3">Prearranged bookings and maximizing efficiencies</h2>
		<p [innerHTML]="aboutUsSecionsData[3]?.content"></p>
	</div>
</section>
<!----We specialize ------------->
<section class="wespecial_wrap">
	<div class="container">
		<h2 class="">We specialize in</h2>
		<p>
			{{aboutUsSecionsData[4]?.content}}
		</p>
		<h2 class="mt-3">Our “UNIQUE SELLING PROPOSITION” (USP)</h2>
		<p [innerHTML]="aboutUsSecionsData[5]?.content"></p>
	</div>
</section>
<!---PERSONAL DRIVER ------------->
<section class="personaldr_wrap">
	<div class="container">
		<div class="row ">
			<div class="col-lg-6">
				<h2 class="">“Your personal driver everywhere.”</h2>
				<p [innerHTML]="aboutUsSecionsData[6]?.content"></p>
			</div>
			<div class="col-lg-6">
				<img src="assets/images/wespecial_img.jpg" alt="wespecial_img">
			</div>
			<div class="col-md-12 mt-3">
				<p>My vision is to take the on-demand business model and create an environment that combines convenience
					with
					reliability, no matter the vehicle you choose or the rate you pay. It’s all about consistent
					personalized
					service and trusted partnerships.
					1-800-LIMO.COM is a global dispatch company and not a TNC (Technology Network Company like U/L.
					Personalized
					service is our specialty.</p>
				<p> We are not simply a middle man, we own the reservation, the client, and take full responsibility for
					the
					booking and your safety. We request all our affiliate partners to enter their lowest rates to keep
					their
					vehicles and Fleet/Coachs busy
					and operating efficiently. Our drivers automatically can add up-to a 20% gratuity on each booking to
					insure
					our drivers earn a living wage, properly maintain their vehicles, and keep the best drivers working
					with us
					and for you.</p>
				<p>
					We are the most transparent company of our kind. The rate you see is that the driver sees. We do not
					mark up
					our drivers all-inclusive rates and we only earn a low 25% commission on each transaction. You have
					you access
					to your account and report information
					24/7.
				</p>
				<p>
					1-800-LIMO.COM does not allow illegal or uninsured operators or with uninspected vehicles to become
					an
					affiliate partner. All our USA drivers will be requested to apply for a FOID card. We do not promote
					gun
					ownership, but state certification is the most
					extensive personal reference check in the industry. If the State approves our drivers credentials,
					you can be
					assured you will be safe and secure in their vehicle. Admittedly, no background check comes with a
					100%
					guarantee however;
					no other transportation company has a more extensive background check policy. You will also have the
					ability
					to rate and review your driver and their vehicle to help us maintain the highest performance
					standards. Our
					mobile App is
					coming soon and we’ll offer a 30% Shared Ride discount when traveling in the same direction. We’ll
					provide
					newer Minivans and SUVs for more leg room, comfort, and luggage space.</p>
			</div>
		</div>
	</div>
</section>